@import url("https://fonts.googleapis.com/css2?family=Murecho:wght@100..900&display=swap");
.h_1 {
  font-family: "Murecho", sans-serif;
  font-weight: 700;
  font-size: 82px;
  line-height: 123px;
}

.h_2 {
  font-family: "Murecho", sans-serif;
  font-weight: 600;
  font-size: 68px;
  line-height: 102px;
}

.h_3 {
  font-family: "Murecho", sans-serif;
  font-weight: 600;
  font-size: 56px;
  line-height: 84px;
}

.h_4 {
  font-family: "Murecho", sans-serif;
  font-weight: 500;
  font-size: 46px;
  line-height: 69px;
}

.h_5 {
  font-family: "Murecho", sans-serif;
  font-weight: 500;
  font-size: 38px;
  line-height: 57px;
}

.h_6 {
  font-family: "Murecho", sans-serif;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
}

.h_7 {
  font-family: "Murecho", sans-serif;
  font-weight: 500;
  font-size: 26px;
  line-height: 39px;
}

.h_8, .alertify .ajs-dialog .ajs-header {
  font-family: "Murecho", sans-serif;
  font-weight: 500;
  font-size: 22px;
  line-height: 33px;
}

.b_1, .alertify .ajs-dialog .ajs-body .ajs-content {
  font-family: "Murecho", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
}

.b_2 {
  font-family: "Murecho", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.b_3 {
  font-family: "Murecho", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.b_4, .alertify .ajs-dialog .ajs-footer .ajs-ok {
  font-family: "Murecho", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.non_select, .non_select--img {
  -webkit-user-select: none;
  -moz-user-select: none;
       user-select: none;
}
.non_select--img {
  pointer-events: none;
}

.alertify .ajs-dialog {
  overflow: hidden;
  border-radius: 16px;
}
.alertify .ajs-dialog .ajs-footer .ajs-ok {
  display: inline-block !important;
  padding: 10px !important;
  font-weight: 700;
}

.third_party_btns {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 15px;
  box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.2509803922);
  border-radius: 12px;
  background-color: #ffffff;
}
.third_party_btns svg {
  width: 30px;
  height: 30px;
}
.third_party_btns.btn_apple {
  fill: #000;
}
.third_party_btns.btn_google path:nth-child(1) {
  fill: #4285f4;
}
.third_party_btns.btn_google path:nth-child(2) {
  fill: #34a853;
}
.third_party_btns.btn_google path:nth-child(3) {
  fill: #fbbc05;
}
.third_party_btns.btn_google path:nth-child(4) {
  fill: #ea4335;
}
.third_party_btns.btn_facebook svg {
  fill: #0866ff;
}

.background_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  color: #ffffff;
  background: #345dff;
  border: 3px solid #345dff;
  padding: 13px 10px;
  border-radius: 12px;
  transition: 300ms all;
}
.background_btn .btn-loader {
  display: none;
}
.background_btn:hover {
  background: #224eff;
  border-color: #224eff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922);
}
.background_btn:disabled, .background_btn[disabled] {
  cursor: not-allowed;
  background: #d2d2d2;
  border-color: #d2d2d2;
  color: #000000;
}
.background_btn:disabled:hover, .background_btn[disabled]:hover {
  box-shadow: 0 0 0 0;
}
.background_btn:disabled:active, .background_btn[disabled]:active {
  border: 3px solid #d2d2d2;
}
.background_btn:active {
  border: 1px solid rgba(0, 0, 0, 0.5019607843);
  box-shadow: 0 0 0 0;
}
.background_btn[data-loader=true] {
  cursor: not-allowed;
  background: #345dff;
  border: 1px solid rgba(0, 0, 0, 0.5019607843);
  color: #ffffff;
}
.background_btn[data-loader=true] .btn-loader {
  display: inline-block;
}
.background_btn[data-loader=true]:hover {
  box-shadow: 0 0 0 0;
}
.background_btn[data-loader=true]:active {
  border: 1px solid rgba(0, 0, 0, 0.5019607843);
}

.outline_btn, .alertify .ajs-dialog .ajs-footer .ajs-ok {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  color: #345dff;
  border: 3px solid #345dff;
  padding: 13px 10px;
  border-radius: 12px;
  background-color: #ffffff;
  transition: 300ms all;
}
.outline_btn .btn-loader, .alertify .ajs-dialog .ajs-footer .ajs-ok .btn-loader {
  display: none;
  border-color: #345dff !important;
}
.outline_btn:hover, .alertify .ajs-dialog .ajs-footer .ajs-ok:hover {
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.2509803922);
}
.outline_btn:disabled, .alertify .ajs-dialog .ajs-footer .ajs-ok:disabled, .outline_btn[disabled], .alertify .ajs-dialog .ajs-footer [disabled].ajs-ok {
  cursor: not-allowed;
  border-color: #bbbbbb;
  color: #bbbbbb;
}
.outline_btn:disabled:hover, .alertify .ajs-dialog .ajs-footer .ajs-ok:disabled:hover, .outline_btn[disabled]:hover, .alertify .ajs-dialog .ajs-footer [disabled].ajs-ok:hover {
  box-shadow: 0 0 0 0;
}
.outline_btn:disabled:active, .alertify .ajs-dialog .ajs-footer .ajs-ok:disabled:active, .outline_btn[disabled]:active, .alertify .ajs-dialog .ajs-footer [disabled].ajs-ok:active {
  background: none;
}
.outline_btn:active, .alertify .ajs-dialog .ajs-footer .ajs-ok:active {
  background-color: #ffffff;
  box-shadow: 0 0 0 0;
}
.outline_btn[data-loader=true], .alertify .ajs-dialog .ajs-footer [data-loader=true].ajs-ok {
  cursor: not-allowed;
  background: #ffffff;
  border-color: #345dff;
  color: #345dff;
}
.outline_btn[data-loader=true] .btn-loader, .alertify .ajs-dialog .ajs-footer [data-loader=true].ajs-ok .btn-loader {
  display: inline-block;
}
.outline_btn[data-loader=true]:hover, .alertify .ajs-dialog .ajs-footer [data-loader=true].ajs-ok:hover {
  box-shadow: 0 0 0 0;
}
.outline_btn[data-loader=true]:active, .alertify .ajs-dialog .ajs-footer [data-loader=true].ajs-ok:active {
  border: 1px solid #bbbbbb;
}

#root .custom-checkbox {
  display: block;
  position: relative;
  padding-left: 20px;
  cursor: pointer;
}
#root .custom-checkbox input[type=checkbox] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
#root .custom-checkbox input[type=checkbox]:checked ~ .checkmark {
  background-color: #e8e8e8;
}
#root .custom-checkbox input[type=checkbox]:checked ~ .checkmark svg {
  display: block;
}
#root .custom-checkbox .checkmark {
  position: absolute;
  top: calc(50% - 8px);
  left: 0;
  height: 16px;
  width: 16px;
  border-radius: 4px;
  background-color: #d2d2d2;
  display: flex;
  align-items: center;
  justify-content: center;
}
#root .custom-checkbox .checkmark svg {
  position: absolute;
  display: none;
  width: 10px;
}
#root .custom-checkbox:hover .checkmark {
  background-color: #bbbbbb;
}

#root .custom-dropdown {
  position: relative;
}
#root .custom-dropdown .disabled {
  display: none;
}
#root .custom-dropdown .select-trigger {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.2509803922);
  border-radius: 12px;
  padding: 5px 8px;
  background-color: #ffffff;
  cursor: pointer;
}
#root .custom-dropdown .select-trigger .content-drop {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 25px;
}
#root .custom-dropdown .select-trigger .content-drop .img-drop {
  height: 70px;
}
#root .custom-dropdown .select-trigger .content-drop .img-drop img {
  width: 100%;
  height: 100%;
}
#root .custom-dropdown .select-trigger .arrow {
  padding: 0 32px;
  transform: rotate(0deg);
  transition: transform 0.3s;
}
#root .custom-dropdown .select-trigger .arrow svg {
  width: 32px;
  height: 32px;
}
#root .custom-dropdown .select-trigger .arrow.open {
  transform: rotate(-180deg);
}
#root .custom-dropdown .select-trigger.open {
  border-radius: 12px 12px 0 0;
}
#root .custom-dropdown .options {
  display: none;
  position: absolute;
  width: 100%;
  background-color: #ffffff;
  display: block;
  z-index: 2;
  box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.2509803922);
  border-radius: 0 0 12px 12px;
  border-top: 0;
  min-height: 100px;
  max-height: 200px;
  overflow: auto;
}
#root .custom-dropdown .options .option {
  display: flex;
  align-items: center;
  padding: 5px 8px;
  gap: 25px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2509803922);
  cursor: pointer;
}
#root .custom-dropdown .options .option .img-drop {
  height: 70px;
}
#root .custom-dropdown .options .option .img-drop img {
  width: 100%;
  height: 100%;
}
#root .custom-dropdown .options .option:nth-last-child(1) {
  border-bottom: 0;
}
#root .custom-dropdown.disabled .disabled {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  z-index: 1;
  cursor: no-drop;
}
#root .phone-dropdown {
  position: relative;
}
#root .phone-dropdown .select-trigger {
  width: -moz-max-content;
  width: max-content;
  display: flex;
  align-items: center;
  border-radius: 8px 0 0 8px;
  padding: 15px 8px;
  background-color: #ffffff;
  cursor: pointer;
}
#root .phone-dropdown .select-trigger .content-drop {
  display: flex;
  align-items: center;
  gap: 12px;
}
#root .phone-dropdown .select-trigger .content-drop .img-drop {
  width: 28px;
  height: 20px;
  border-radius: 2px;
  overflow: hidden;
}
#root .phone-dropdown .select-trigger .content-drop .img-drop img {
  width: 100%;
  height: 100%;
}
#root .phone-dropdown .select-trigger .content-drop .txt .b_4, #root .phone-dropdown .select-trigger .content-drop .txt .alertify .ajs-dialog .ajs-footer .ajs-ok, .alertify .ajs-dialog .ajs-footer #root .phone-dropdown .select-trigger .content-drop .txt .ajs-ok {
  font-weight: 500;
}
#root .phone-dropdown .select-trigger .arrow {
  transform: rotate(0deg);
  transition: transform 0.3s;
}
#root .phone-dropdown .select-trigger .arrow svg {
  width: 16px;
  height: 16px;
}
#root .phone-dropdown .select-trigger .arrow.open {
  transform: rotate(-180deg);
}
#root .phone-dropdown .options {
  display: none;
  position: absolute;
  width: -moz-max-content;
  width: max-content;
  background-color: #ffffff;
  display: block;
  z-index: 2;
  border-radius: 0 0 4px 4px;
  min-height: 60px;
  max-height: 120px;
  overflow: auto;
  box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.2509803922);
  -webkit-clip-path: inset(0px -60px -60px -60px);
          clip-path: inset(0px -60px -60px -60px);
}
#root .phone-dropdown .options .option {
  display: flex;
  align-items: center;
  padding: 15px 8px;
  gap: 12px;
  justify-content: space-between;
  border-bottom: 2px solid rgba(39, 148, 233, 0.1019607843);
  cursor: pointer;
}
#root .phone-dropdown .options .option .img-drop {
  width: 28px;
  height: 20px;
  border-radius: 2px;
  overflow: hidden;
}
#root .phone-dropdown .options .option .img-drop img {
  width: 100%;
  height: 100%;
}
#root .phone-dropdown .options .option .txt .b_4, #root .phone-dropdown .options .option .txt .alertify .ajs-dialog .ajs-footer .ajs-ok, .alertify .ajs-dialog .ajs-footer #root .phone-dropdown .options .option .txt .ajs-ok {
  font-weight: 500;
}
#root .phone-dropdown .options .option:nth-last-child(1) {
  border-bottom: 0;
}
#root .multilang-dropdown {
  position: relative;
}
#root .multilang-dropdown .select-trigger {
  width: -moz-max-content;
  width: max-content;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border: 2px solid #bbbbbb;
  border-radius: 4px;
  padding: 2px 6px;
  cursor: pointer;
}
#root .multilang-dropdown .select-trigger .content-drop {
  display: flex;
  align-items: center;
  gap: 5px;
}
#root .multilang-dropdown .select-trigger .content-drop .img-drop {
  width: 28px;
  height: 20px;
  border-radius: 2px;
  overflow: hidden;
}
#root .multilang-dropdown .select-trigger .content-drop .img-drop img {
  width: 100%;
  height: 100%;
}
#root .multilang-dropdown .select-trigger .content-drop .txt .b_1, #root .multilang-dropdown .select-trigger .content-drop .txt .alertify .ajs-dialog .ajs-body .ajs-content, .alertify .ajs-dialog .ajs-body #root .multilang-dropdown .select-trigger .content-drop .txt .ajs-content {
  font-weight: 500;
}
#root .packages-dropdown {
  width: 15vw;
  position: relative;
}
#root .packages-dropdown .select-trigger {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 10px 15px;
  box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.2509803922);
  cursor: pointer;
}
#root .packages-dropdown .select-trigger .content-drop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
}
#root .packages-dropdown .select-trigger .content-drop .txt .b_3 {
  font-weight: 500;
}
#root .packages-dropdown .select-trigger .content-drop .arrow {
  width: 8px;
  display: flex;
  align-items: center;
}
#root .packages-dropdown .select-trigger .content-drop .arrow svg {
  width: 100%;
  height: 100%;
}
#root .packages-dropdown .select-trigger.open {
  border-radius: 8px 8px 0 0;
}
#root .packages-dropdown .options {
  display: none;
  position: absolute;
  width: 100%;
  background-color: #ffffff;
  display: block;
  z-index: 2;
  border-radius: 0 0 8px 8px;
  min-height: 60px;
  max-height: 120px;
  overflow: auto;
  box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.2509803922);
  -webkit-clip-path: inset(0px -60px -60px -60px);
          clip-path: inset(0px -60px -60px -60px);
}
#root .packages-dropdown .options .option {
  display: flex;
  align-items: center;
  padding: 15px 8px;
  gap: 12px;
  justify-content: space-between;
  border-bottom: 2px solid rgba(39, 148, 233, 0.1019607843);
  cursor: pointer;
}
#root .packages-dropdown .options .option .img-drop {
  width: 28px;
  height: 20px;
  border-radius: 2px;
  overflow: hidden;
}
#root .packages-dropdown .options .option .img-drop img {
  width: 100%;
  height: 100%;
}
#root .packages-dropdown .options .option:nth-last-child(1) {
  border-bottom: 0;
}

#root .main_ads_or_img {
  margin: 95px 0 70px 0;
  height: 170px;
  border-radius: 12px;
  overflow: hidden;
}
#root .main_ads_or_img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

#root .phone-input {
  display: flex;
  border-radius: 8px;
  box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.2509803922);
}
#root .phone-input input {
  border: none;
  border-radius: 0 8px 8px 0;
  padding: 15px 10px;
}
#root .phone-input:has(.select-trigger.open) {
  border-radius: 8px 8px 8px 0;
}
#root .phone-input.correct {
  box-shadow: 0px 5px 16px 0px rgba(31, 193, 107, 0.5098039216);
}
#root .phone-input.correct .phone-dropdown .options {
  box-shadow: 0px 5px 16px 0px rgba(31, 193, 107, 0.5098039216);
}
#root .phone-input.wrong {
  box-shadow: 0px 5px 16px 0px rgba(208, 4, 22, 0.5098039216);
}
#root .phone-input.wrong .phone-dropdown .options {
  box-shadow: 0px 5px 16px 0px rgba(208, 4, 22, 0.5098039216);
}
#root .input {
  min-width: 250px;
  border: none;
  border-radius: 8px;
  padding: 15px 10px;
  box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.2509803922);
}
#root .input.correct {
  box-shadow: 0px 5px 16px 0px rgba(31, 193, 107, 0.5098039216);
}
#root .input.wrong {
  box-shadow: 0px 5px 16px 0px rgba(208, 4, 22, 0.5098039216);
}

.match_component {
  width: calc(50% - 24px);
  box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.2509803922);
  border-radius: 16px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 45px 12px;
  gap: 12px;
  position: relative;
}
.match_component .background_btn.btn--delete_to_basket {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  border-radius: 50%;
  padding: 5px 8px;
  box-shadow: 0px 5px 16px 0px rgba(31, 193, 107, 0.5098039216);
  background-color: #ffffff;
  border: none;
}
.match_component .background_btn.btn--delete_to_basket .btn-loader {
  border-top: 2px solid #d00416;
}
.match_component .background_btn.btn--delete_to_basket figure {
  width: 14px;
}
.match_component .background_btn.btn--delete_to_basket figure svg {
  width: 100%;
  height: 100%;
  fill: #d00416;
}
.match_component .existing_info {
  position: absolute;
  top: 15px;
  left: 15px;
  cursor: pointer;
}
.match_component .existing_info figure {
  display: flex;
  width: 16px;
  height: 16px;
  padding: 2px;
  border-radius: 50%;
  border: 1px solid #1f9960;
  animation: opacity 1s linear infinite;
}
.match_component .existing_info figure svg {
  width: 100%;
  height: 100%;
  fill: #1f9960;
}
.match_component .existing_info .modal {
  position: absolute;
  bottom: 16px;
  left: 16px;
  padding: 14px 8px;
  border: 1px solid #1f9960;
  background: #ffffff;
  border-radius: 4px 4px 4px 0;
  width: -moz-max-content;
  width: max-content;
  color: #1f9960;
  display: none;
}
.match_component .existing_info:hover figure {
  animation: none;
}
.match_component .existing_info:hover .modal {
  display: inline-block;
}
.match_component .match_content {
  width: 100%;
}
.match_component .match_content h5.h_8, .match_component .match_content .alertify .ajs-dialog h5.ajs-header, .alertify .ajs-dialog .match_component .match_content h5.ajs-header {
  text-align: center;
  color: rgba(0, 0, 0, 0.5019607843);
}
.match_component .match_content .teams .team_names {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.match_component .match_content .teams .team_names .team {
  flex: 1 1;
  color: rgba(0, 0, 0, 0.5019607843);
}
.match_component .match_content .teams .team_names .team.left {
  text-align: left;
}
.match_component .match_content .teams .team_names .team.right {
  text-align: right;
}
.match_component .match_content .teams .team_names span.vs {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.5019607843);
}
.match_component .match_content .teams .teams_last_matches {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.match_component .match_content .teams .teams_last_matches .team_last_matches {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.match_component .match_content .teams .teams_last_matches .team_last_matches .b_4, .match_component .match_content .teams .teams_last_matches .team_last_matches .alertify .ajs-dialog .ajs-footer .ajs-ok, .alertify .ajs-dialog .ajs-footer .match_component .match_content .teams .teams_last_matches .team_last_matches .ajs-ok {
  color: rgba(0, 0, 0, 0.5019607843);
}
.match_component .match_content .teams .teams_last_matches .team_last_matches .data {
  display: flex;
  align-items: center;
  gap: 8px;
}
.match_component .match_content .teams .teams_last_matches .team_last_matches .data .match_info {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.5019607843);
  position: relative;
}
.match_component .match_content .teams .teams_last_matches .team_last_matches .data .match_info .modal {
  display: none;
  position: absolute;
  top: 12px;
  z-index: 1;
  padding: 8px;
  color: #000000;
  border: 1px solid #000000;
  background: #ffffff;
  width: -moz-max-content;
  width: max-content;
}
.match_component .match_content .teams .teams_last_matches .team_last_matches .data .match_info.W {
  background-color: #1fc16b;
  border: 1px solid #1fc16b;
}
.match_component .match_content .teams .teams_last_matches .team_last_matches .data .match_info.L {
  background-color: #d00416;
  border: 1px solid #d00416;
}
.match_component .match_content .teams .teams_last_matches .team_last_matches .data .match_info.D {
  background-color: #dfb400;
  border: 1px solid #dfb400;
}
.match_component .match_content .teams .teams_last_matches .team_last_matches .data .match_info:hover .modal {
  display: inline-block;
}
.match_component .match_content .teams .teams_last_matches .team_last_matches.left {
  text-align: left;
  align-items: flex-start;
}
.match_component .match_content .teams .teams_last_matches .team_last_matches.left .modal {
  border-radius: 0px 4px 4px 4px;
  left: 3px;
  text-align: left;
}
.match_component .match_content .teams .teams_last_matches .team_last_matches.right {
  text-align: right;
  align-items: flex-end;
}
.match_component .match_content .teams .teams_last_matches .team_last_matches.right .modal {
  right: 3px;
  border-radius: 4px 0px 4px 4px;
  text-align: right;
}
.match_component .match_content .statistics {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border: 3px solid rgba(51, 51, 51, 0.4);
  padding: 10px;
  border-radius: 24px;
  margin-top: 25px;
}
.match_component .match_content .statistics .b_2 {
  font-weight: 500;
}
.match_component .match_content .statistics .statistic {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}
.match_component .match_content .statistics .statistic .b_3 {
  color: #333333;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.match_component .background_btn {
  border-radius: 1500px;
  padding: 12px 48px;
}
.match_component .background_btn .b_3 {
  font-weight: 600;
}
.match_component .background_btn:disabled, .match_component .background_btn[disabled] {
  background: none;
  border-color: #345dff;
  color: #345dff;
}
.match_component .selected_and_delete {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
}
.match_component .selected_and_delete .selected_and_point {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.match_component .selected_and_delete .selected_and_point .prediction_selected {
  flex: 1 1;
}
.match_component .selected_and_delete .selected_and_point .prediction_selected .h_8, .match_component .selected_and_delete .selected_and_point .prediction_selected .alertify .ajs-dialog .ajs-header, .alertify .ajs-dialog .match_component .selected_and_delete .selected_and_point .prediction_selected .ajs-header {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.match_component .selected_and_delete .selected_and_point .prediction_selected .h_8 span, .match_component .selected_and_delete .selected_and_point .prediction_selected .alertify .ajs-dialog .ajs-header span, .alertify .ajs-dialog .match_component .selected_and_delete .selected_and_point .prediction_selected .ajs-header span {
  color: #345dff;
}
.match_component .selected_and_delete .selected_and_point .prediction_point {
  flex: 1 1;
}
.match_component .selected_and_delete .selected_and_point .prediction_point .h_8, .match_component .selected_and_delete .selected_and_point .prediction_point .alertify .ajs-dialog .ajs-header, .alertify .ajs-dialog .match_component .selected_and_delete .selected_and_point .prediction_point .ajs-header {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.match_component .selected_and_delete .selected_and_point .prediction_point .h_8 span, .match_component .selected_and_delete .selected_and_point .prediction_point .alertify .ajs-dialog .ajs-header span, .alertify .ajs-dialog .match_component .selected_and_delete .selected_and_point .prediction_point .ajs-header span {
  color: #345dff;
}
.match_component .selected_and_delete .delete .background_btn {
  padding: 6px 36px;
}
.match_component.added {
  box-shadow: 0px 5px 16px 0px rgba(31, 193, 107, 0.5098039216);
}
.match_component.added .buttons {
  justify-content: space-between;
}

@keyframes opacity {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
}
#root .modal-message {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2509803922);
  z-index: 99;
}
#root .modal_prediction {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 9;
  background-color: rgba(0, 0, 0, 0.2509803922);
  display: flex;
  align-items: center;
  justify-content: center;
}
#root .modal_prediction .match {
  width: 40vw;
  box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.2509803922);
  border-radius: 12px;
  background-color: #ffffff;
  overflow: hidden;
}
#root .modal_prediction .match .match_scroll {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  gap: 12px;
  overflow: auto;
  max-height: 90vh;
}
#root .modal_prediction .match .match_scroll .modal_close {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  border-radius: 50%;
  padding: 5px 8px;
  box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.2509803922);
  background-color: #ffffff;
  cursor: pointer;
}
#root .modal_prediction .match .match_scroll .modal_close figure {
  display: flex;
  width: 14px;
}
#root .modal_prediction .match .match_scroll .modal_close figure svg {
  width: 100%;
  height: 100%;
  fill: rgba(0, 0, 0, 0.5019607843);
}
#root .modal_prediction .match .match_scroll .match_content {
  width: 100%;
}
#root .modal_prediction .match .match_scroll .match_content h5.h_8, #root .modal_prediction .match .match_scroll .match_content .alertify .ajs-dialog h5.ajs-header, .alertify .ajs-dialog #root .modal_prediction .match .match_scroll .match_content h5.ajs-header {
  text-align: center;
  color: rgba(0, 0, 0, 0.5019607843);
}
#root .modal_prediction .match .match_scroll .match_content .teams .team_names {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#root .modal_prediction .match .match_scroll .match_content .teams .team_names .team {
  flex: 1 1;
  color: rgba(0, 0, 0, 0.5019607843);
}
#root .modal_prediction .match .match_scroll .match_content .teams .team_names .team.left {
  text-align: left;
}
#root .modal_prediction .match .match_scroll .match_content .teams .team_names .team.right {
  text-align: right;
}
#root .modal_prediction .match .match_scroll .match_content .teams .team_names span.vs {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.5019607843);
}
#root .modal_prediction .match .match_scroll .match_content .teams .teams_last_matches {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#root .modal_prediction .match .match_scroll .match_content .teams .teams_last_matches .team_last_matches {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
#root .modal_prediction .match .match_scroll .match_content .teams .teams_last_matches .team_last_matches .b_4, #root .modal_prediction .match .match_scroll .match_content .teams .teams_last_matches .team_last_matches .alertify .ajs-dialog .ajs-footer .ajs-ok, .alertify .ajs-dialog .ajs-footer #root .modal_prediction .match .match_scroll .match_content .teams .teams_last_matches .team_last_matches .ajs-ok {
  color: rgba(0, 0, 0, 0.5019607843);
}
#root .modal_prediction .match .match_scroll .match_content .teams .teams_last_matches .team_last_matches .data {
  display: flex;
  align-items: center;
  gap: 8px;
}
#root .modal_prediction .match .match_scroll .match_content .teams .teams_last_matches .team_last_matches .data .match_info {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.5019607843);
  position: relative;
}
#root .modal_prediction .match .match_scroll .match_content .teams .teams_last_matches .team_last_matches .data .match_info .modal {
  display: none;
  position: absolute;
  top: 12px;
  z-index: 1;
  padding: 8px;
  color: #000000;
  border: 1px solid #000000;
  background: #ffffff;
  width: -moz-max-content;
  width: max-content;
}
#root .modal_prediction .match .match_scroll .match_content .teams .teams_last_matches .team_last_matches .data .match_info.W {
  background-color: #1fc16b;
  border: 1px solid #1fc16b;
}
#root .modal_prediction .match .match_scroll .match_content .teams .teams_last_matches .team_last_matches .data .match_info.L {
  background-color: #d00416;
  border: 1px solid #d00416;
}
#root .modal_prediction .match .match_scroll .match_content .teams .teams_last_matches .team_last_matches .data .match_info.D {
  background-color: #dfb400;
  border: 1px solid #dfb400;
}
#root .modal_prediction .match .match_scroll .match_content .teams .teams_last_matches .team_last_matches .data .match_info:hover .modal {
  display: inline-block;
}
#root .modal_prediction .match .match_scroll .match_content .teams .teams_last_matches .team_last_matches.left {
  text-align: left;
  align-items: flex-start;
}
#root .modal_prediction .match .match_scroll .match_content .teams .teams_last_matches .team_last_matches.left .modal {
  border-radius: 0px 4px 4px 4px;
  left: 3px;
  text-align: left;
}
#root .modal_prediction .match .match_scroll .match_content .teams .teams_last_matches .team_last_matches.right {
  text-align: right;
  align-items: flex-end;
}
#root .modal_prediction .match .match_scroll .match_content .teams .teams_last_matches .team_last_matches.right .modal {
  right: 3px;
  border-radius: 4px 0px 4px 4px;
  text-align: right;
}
#root .modal_prediction .match .match_scroll .match_content .teams .teams_last_matches .team_last_matches.right .data {
  flex-direction: row-reverse;
}
#root .modal_prediction .match .match_scroll .divider {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 15px;
}
#root .modal_prediction .match .match_scroll .divider hr {
  width: 100%;
  height: 1px;
  color: rgba(0, 0, 0, 0.2509803922);
  background-color: rgba(0, 0, 0, 0.2509803922);
}
#root .modal_prediction .match .match_scroll .divider figure {
  width: 50px;
  display: flex;
}
#root .modal_prediction .match .match_scroll .divider figure svg {
  width: 100%;
  height: 100%;
  fill: rgba(0, 0, 0, 0.5019607843);
}
#root .modal_prediction .match .match_scroll .predictions_and_scores {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
}
#root .modal_prediction .match .match_scroll .predictions_and_scores .option {
  border-radius: 1500px;
  cursor: pointer;
}
#root .modal_prediction .match .match_scroll .predictions_and_scores .option.active {
  background-color: #345dff;
  color: #ffffff;
}
#root .modal_prediction .match .match_scroll .predictions_and_scores .predictions {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 16px;
}
#root .modal_prediction .match .match_scroll .predictions_and_scores .predictions .option {
  width: 100%;
  font-weight: 600;
  padding: 12px 12px;
}
#root .modal_prediction .match .match_scroll .predictions_and_scores .scores {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
}
#root .modal_prediction .match .match_scroll .predictions_and_scores .scores .option {
  width: calc(20% - 16px);
  padding: 10px;
}
#root .modal_prediction .match .match_scroll .predictions_and_scores .scores:has(.option:last-child:nth-child(5n)) {
  justify-content: space-between;
}
#root .modal_prediction .match .match_scroll .background_btn {
  border-radius: 1500px;
  padding: 12px 48px;
  margin-top: 24px;
}
#root .modal_prediction .match .match_scroll .background_btn .b_3 {
  font-weight: 600;
}
#root .modal_report_user {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 9;
  background-color: rgba(0, 0, 0, 0.2509803922);
  display: flex;
  align-items: center;
  justify-content: center;
}
#root .modal_report_user .popup_report_user {
  width: 60vw;
  box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.2509803922);
  border-radius: 24px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  gap: 12px;
  max-height: 100vh;
  overflow: auto;
}
#root .modal_report_user .popup_report_user .top .user_details {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}
#root .modal_report_user .popup_report_user .top .user_details figure {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.2509803922);
}
#root .modal_report_user .popup_report_user .top .user_details figure img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
#root .modal_report_user .popup_report_user .top .report h6.h_6 {
  text-align: center;
  margin: 20px 0;
}
#root .modal_report_user .popup_report_user .top .report .report_type {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
#root .modal_report_user .popup_report_user .top .report .report_type div label {
  margin-left: 5px;
}
#root .modal_report_user .popup_report_user .top .report .report_type div label span {
  color: #d00416;
  margin-left: 5px;
}
#root .modal_report_user .popup_report_user .top .report .desc {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 10px 0 30px 0;
}
#root .modal_report_user .popup_report_user .top .report .desc label span {
  color: #d00416;
  margin-left: 5px;
}
#root .modal_report_user .popup_report_user .top .report .desc textarea {
  resize: none;
  height: 150px;
}
#root .modal_report_user .popup_report_user .buttons {
  display: flex;
  justify-content: space-evenly;
  align-items: stretch;
  width: 100%;
}
#root .modal_report_user .popup_report_user .buttons .outline_btn, #root .modal_report_user .popup_report_user .buttons .alertify .ajs-dialog .ajs-footer .ajs-ok, .alertify .ajs-dialog .ajs-footer #root .modal_report_user .popup_report_user .buttons .ajs-ok,
#root .modal_report_user .popup_report_user .buttons .background_btn {
  padding: 7px 25px;
  border-radius: 1500px;
}
#root .modal_report_user .popup_report_user .buttons .background_btn {
  background-color: rgba(208, 4, 22, 0.5098039216);
  border: none;
}

.mini-loader {
  background-color: #ffffff;
}
.mini-loader .loader {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 50px;
  height: 50px;
  font-size: 0;
  display: inline-block;
  margin: -25px 0 0 -25px;
  text-indent: -9999em;
  transform: translateZ(0);
}
.mini-loader .loader div {
  background-color: #1a4573;
  display: inline-block;
  float: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
  opacity: 0.5;
  border-radius: 50%;
  animation: mini-loader 2s ease-in-out infinite;
}
.mini-loader .loader div:last-child {
  animation-delay: -1s;
}

.btn-loader {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: inline-block;
  border-top: 3px solid #ffffff;
  border-right: 3px solid transparent;
  box-sizing: border-box;
  animation: btn-loader 1s linear infinite;
}
.btn-loader.m {
  width: 20px;
  height: 20px;
}
.btn-loader.s {
  width: 15px;
  height: 15px;
  border-top: 2px solid #ffffff;
  border-right: 2px solid transparent;
}

@keyframes mini-loader {
  0%, 100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}
@keyframes btn-loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.progress_bar {
  position: relative;
  width: 100px;
  height: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2509803922);
  border-radius: 50px;
  overflow: hidden;
}
.progress_bar .progress {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  height: 100%;
  background-color: #345dff;
}

* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  box-sizing: border-box;
  list-style-type: none;
  outline: none;
}

body {
  font-family: "Murecho", sans-serif;
  overflow-x: hidden;
  background-image: url("/public/img/background.svg"), url("/public/img/background.svg");
  background-size: 500px 500px;
  background-position: 0 0, 250px 250px;
}

a,
button {
  border: none;
  cursor: pointer;
}

iframe#webpack-dev-server-client-overlay {
  display: none;
  opacity: 0 !important;
}

html {
  scrollbar-color: #345dff rgba(39, 148, 233, 0.1019607843);
  scrollbar-width: thin;
}
html #root #overflow-register {
  scrollbar-width: thin;
}
html #root .hide-scroll {
  scrollbar-color: auto;
  scrollbar-width: auto;
}
html #root .hide-scroll::-webkit-scrollbar {
  width: 3px;
}
html #root .hide-scroll::-webkit-scrollbar-track {
  background: rgba(39, 148, 233, 0.1019607843);
}
html #root .hide-scroll::-webkit-scrollbar-thumb {
  background: #0c3dff;
  border-radius: 2px;
}
html.firefox {
  scrollbar-color: #345dff rgba(39, 148, 233, 0.1019607843);
  scrollbar-width: auto;
}
html.firefox #root #overflow-register {
  scrollbar-width: auto;
}
html.firefox #root .hide-scroll {
  scrollbar-color: #345dff rgba(39, 148, 233, 0.1019607843);
  scrollbar-width: thin;
}

#root .verify-code-inputs .vi__container {
  width: 100%;
  gap: 24px;
  height: -moz-fit-content;
  height: fit-content;
}
#root .verify-code-inputs .vi__container .vi__character {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1;
  aspect-ratio: 1/1;
  height: auto;
  padding: 8px;
  border-radius: 12px;
  font-size: 56px;
  font-weight: 600;
  line-height: 84px;
  border: none;
  box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.2509803922);
  transition: 300ms;
}
#root .verify-code-inputs .vi__container .vi__character.vi__character--inactive {
  background-color: #ffffff;
}
#root .verify-code-inputs .vi__container .vi__character:where(.vi__character--selected) {
  outline: none;
  box-shadow: 0px 5px 16px 0px rgba(52, 93, 255, 0.5019607843);
  color: #345dff;
}

#root .bug_report_sect {
  border-top: 1px solid #1a4573;
  padding-top: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}
#root .bug_report_sect .bug_report_page {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50vw;
}
#root .bug_report_sect .bug_report_page .h_6 {
  color: #1a4573;
}
#root .bug_report_sect .bug_report_page form .inputs {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
#root .bug_report_sect .bug_report_page form .inputs div {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
#root .bug_report_sect .bug_report_page form .inputs div.subject_image {
  flex-direction: row;
}
#root .bug_report_sect .bug_report_page form .inputs div.subject_image div {
  width: 50%;
}
#root .bug_report_sect .bug_report_page form .inputs div.subject_image div .input {
  background-color: #ffffff;
}
#root .bug_report_sect .bug_report_page form .inputs label {
  margin-bottom: -10px;
}
#root .bug_report_sect .bug_report_page form .inputs label span {
  color: #d00416;
  margin-left: 5px;
}
#root .bug_report_sect .bug_report_page form .inputs input,
#root .bug_report_sect .bug_report_page form .inputs textarea,
#root .bug_report_sect .bug_report_page form .inputs select {
  padding: 10px;
}
#root .bug_report_sect .bug_report_page form .inputs textarea {
  resize: none;
  height: 150px;
}
#root .bug_report_sect .bug_report_page form .background_btn {
  width: 100%;
  margin-top: 20px;
  padding: 10px;
  border-radius: 8px;
}

#root footer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background: #1a4573;
  padding: 55px 80px 67px 80px;
  gap: 50px;
}
#root footer .top {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
}
#root footer .top .logo {
  flex: 1 1 33.3333333333%;
  margin-bottom: -20px;
}
#root footer .top .logo .link {
  display: flex;
  width: -moz-fit-content;
  width: fit-content;
}
#root footer .top .logo .link figure {
  width: 140px;
}
#root footer .top .logo .link figure img {
  width: 100%;
  height: 100%;
}
#root footer .top .mid {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 82px;
  flex: 1 1 33.3333333333%;
}
#root footer .top .mid .h_5 {
  color: #ffffff;
  width: 650px;
  text-align: center;
}
#root footer .top .mid .background_btn {
  width: 460px;
  background: #ffffff;
  border-color: #ffffff;
  color: #000000;
}
#root footer .top .mid .pages {
  display: flex;
  align-items: center;
  gap: 40px;
}
#root footer .top .mid .pages .h_8, #root footer .top .mid .pages .alertify .ajs-dialog .ajs-header, .alertify .ajs-dialog #root footer .top .mid .pages .ajs-header {
  color: #ffffff;
}
#root footer .top .social-medias {
  flex: 1 1 33.3333333333%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 35px;
}
#root footer .top .social-medias figure {
  height: 19px;
}
#root footer .top .social-medias figure svg {
  width: 100%;
  height: 100%;
  fill: #ffffff;
}
#root footer .conditions {
  display: flex;
  gap: 30px;
}
#root footer .conditions .b_3 {
  color: #ffffff;
}
#root footer.footer-mini {
  padding: 38px 12vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
#root footer.footer-mini figure {
  width: 140px;
}
#root footer.footer-mini figure img {
  width: 100%;
  height: 100%;
}
#root footer.footer-mini .links {
  display: flex;
  justify-content: space-between;
  gap: 10vw;
}
#root footer.footer-mini .links div {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
#root footer.footer-mini .links div .b_1, #root footer.footer-mini .links div .alertify .ajs-dialog .ajs-body .ajs-content, .alertify .ajs-dialog .ajs-body #root footer.footer-mini .links div .ajs-content {
  color: #ffffff;
}
#root footer.footer-mini .social-icons {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
#root footer.footer-mini .social-icons figure {
  display: flex;
}
#root footer.footer-mini .social-icons figure svg {
  fill: #ffffff;
  width: 100%;
  height: 100%;
}
#root footer.footer-mini .social-icons figure.twitter {
  width: 16px;
}
#root footer.footer-mini .social-icons figure.facebook {
  width: 10px;
}
#root footer.footer-mini .social-icons figure.instagram {
  width: 19px;
}
#root footer.footer-mini .markets {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
#root footer.footer-mini .markets figure {
  width: auto;
  height: 55px;
  display: flex;
}
#root footer.footer-mini .markets figure img {
  width: 100%;
  height: 100%;
}

#root nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 12vw;
  border-radius: 0 0 16px 16px;
  box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.2509803922);
  background-color: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 5;
}
#root nav .logo figure {
  display: flex;
  width: 140px;
}
#root nav .logo figure img {
  width: 100%;
  height: 100%;
}
#root nav .nav-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
}
#root nav .nav-content .pages {
  display: flex;
  align-items: center;
  gap: 24px;
}
#root nav .nav-content .pages .b_1, #root nav .nav-content .pages .alertify .ajs-dialog .ajs-body .ajs-content, .alertify .ajs-dialog .ajs-body #root nav .nav-content .pages .ajs-content {
  color: #000000;
}
#root nav .nav-content .pages .prediction_link {
  position: relative;
  margin-right: 10px;
}
#root nav .nav-content .pages .prediction_link span {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -5px;
  right: -20px;
  background: #345dff;
  border-radius: 50%;
  color: #ffffff;
}
#root nav .nav-content .nav-btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}
#root nav .nav-content .nav-btns .b_2 {
  padding: 8px 12px;
  border-radius: 150px;
}
#root nav .nav-content .user {
  display: flex;
  align-items: center;
  gap: 24px;
}
#root nav .nav-content .user .notifications figure {
  height: 25px;
}
#root nav .nav-content .user .notifications figure svg {
  width: 100%;
  height: 100%;
  fill: #000000;
}
#root nav .nav-content .user .profile {
  position: relative;
  display: flex;
  align-items: center;
  gap: 25px;
}
#root nav .nav-content .user .profile figure {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 50%;
  box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.2509803922);
}
#root nav .nav-content .user .profile figure img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
#root nav .nav-content .user .profile .point {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
}
#root nav .nav-content .user .profile .point .b_2 {
  font-weight: 600;
  color: #345dff;
  filter: drop-shadow(0.5px 0 0 rgba(39, 148, 233, 0.1019607843)) drop-shadow(0 0.5px 0 rgba(39, 148, 233, 0.1019607843)) drop-shadow(-0.5px 0 0 rgba(39, 148, 233, 0.1019607843)) drop-shadow(0 -0.5px 0 rgba(39, 148, 233, 0.1019607843));
}

#root .achievements_component {
  margin-top: 50px;
}
#root .achievements_component .hood {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 40px;
}
#root .achievements_component .hood .outline_btn.b_1, #root .achievements_component .hood .alertify .ajs-dialog .ajs-footer .b_1.ajs-ok, .alertify .ajs-dialog .ajs-footer #root .achievements_component .hood .b_1.ajs-ok, #root .achievements_component .hood .alertify .ajs-dialog .ajs-body .outline_btn.ajs-content, .alertify .ajs-dialog .ajs-body #root .achievements_component .hood .outline_btn.ajs-content, #root .achievements_component .hood .alertify .ajs-dialog .ajs-footer .ajs-body .ajs-ok.ajs-content, .alertify .ajs-dialog .ajs-footer .ajs-body #root .achievements_component .hood .ajs-ok.ajs-content, #root .achievements_component .hood .alertify .ajs-dialog .ajs-body .ajs-footer .ajs-ok.ajs-content, .alertify .ajs-dialog .ajs-body .ajs-footer #root .achievements_component .hood .ajs-ok.ajs-content {
  padding: 2px 10px;
  border-radius: 16px;
  font-weight: 500;
}
#root .achievements_component .achievements {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  gap: 36px;
  width: 100%;
}
#root .achievement_card {
  display: flex;
  align-items: center;
  gap: 12px;
  background-color: #ffffff;
  box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.2509803922);
  border-radius: 16px;
  padding: 10px;
  width: calc((100% - 72px) / 3);
  position: relative;
  overflow: hidden;
}
#root .achievement_card figure {
  width: 60px;
  display: flex;
}
#root .achievement_card figure img {
  width: 100%;
  height: 100%;
}
#root .achievement_card .block {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2509803922);
  cursor: not-allowed;
}
#root .cond-use {
  margin-top: 75px;
  padding: 30px 12vw 60px 12vw;
  min-height: 100vh;
}
#root .cond-use .h_2 {
  text-align: center;
  margin: 0 0 30px 0;
}
#root .cond-use .conditions {
  display: flex;
  flex-wrap: wrap;
  gap: 35px;
}
#root .cond-use .conditions .condition {
  flex: 1 1 100%;
  padding: 56px;
  box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.2509803922);
  border-radius: 16px;
  background-color: #ffffff;
  transition: 300ms all;
}
#root .cond-use .conditions .condition .desc {
  margin-top: 50px;
}
#root .cond-use .conditions .condition .b_1 span, #root .cond-use .conditions .condition .alertify .ajs-dialog .ajs-body .ajs-content span, .alertify .ajs-dialog .ajs-body #root .cond-use .conditions .condition .ajs-content span {
  font-weight: 700;
}
#root .cond-use .conditions .condition.half {
  flex: 1 1 calc(50% - 17.5px);
  padding: 28px 56px;
}
#root .cond-use .conditions .condition:hover {
  box-shadow: 0px 5px 16px 0px rgba(52, 93, 255, 0.5019607843);
}
#root .privacy-policy {
  margin-top: 75px;
  padding: 30px 12vw 60px 12vw;
  min-height: 100vh;
}
#root .privacy-policy .h_2 {
  text-align: center;
  margin: 0 0 30px 0;
}
#root .privacy-policy .policies {
  display: flex;
  flex-wrap: wrap;
  gap: 35px;
}
#root .privacy-policy .policies .policy {
  flex: 1 1 100%;
  padding: 56px;
  box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.2509803922);
  border-radius: 16px;
  transition: 300ms all;
  background-color: #ffffff;
}
#root .privacy-policy .policies .policy .b_1 span, #root .privacy-policy .policies .policy .alertify .ajs-dialog .ajs-body .ajs-content span, .alertify .ajs-dialog .ajs-body #root .privacy-policy .policies .policy .ajs-content span {
  font-weight: 700;
}
#root .privacy-policy .policies .policy.half {
  flex: 1 1 calc(50% - 17.5px);
  padding: 28px 56px;
}
#root .privacy-policy .policies .policy:hover {
  box-shadow: 0px 5px 16px 0px rgba(52, 93, 255, 0.5019607843);
}
#root .faqs {
  margin-top: 75px;
  padding: 30px 12vw;
  min-height: 100vh;
}
#root .faqs .main-hood {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}
#root .faqs .main-hood .h_6 {
  color: #05092f;
}
#root .faqs .questions-category .h_7 {
  margin: 30px 0 25px 0;
}
#root .faqs .questions-category .questions {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
#root .faqs .questions-category .questions .question {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px;
  border-radius: 14px;
  box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.2509803922);
  background-color: #ffffff;
  cursor: pointer;
  transition: 300ms all;
}
#root .faqs .questions-category .questions .question .content {
  flex: 1 1 100%;
  display: inline;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  transition: 300ms all;
}
#root .faqs .questions-category .questions .question .content .hood {
  color: #05092f;
}
#root .faqs .questions-category .questions .question .content .open-txt {
  max-height: 0;
  overflow: hidden;
  transition: 300ms all;
}
#root .faqs .questions-category .questions .question .arrow {
  flex: 1 1 20%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}
#root .faqs .questions-category .questions .question .arrow figure {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 17px;
  border-radius: 50%;
  height: -moz-fit-content;
  height: fit-content;
  box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.2509803922);
  transform: rotate(-90deg);
  transition: 300ms all;
}
#root .faqs .questions-category .questions .question .arrow figure svg {
  width: 16px;
  fill: #345dff;
  transition: 300ms all;
}
#root .faqs .questions-category .questions .question.open {
  align-items: flex-start;
  box-shadow: 0px 5px 16px 0px rgba(52, 93, 255, 0.5019607843);
}
#root .faqs .questions-category .questions .question.open .content {
  display: flex;
}
#root .faqs .questions-category .questions .question.open .content .open-txt {
  max-height: 1440px;
}
#root .faqs .questions-category .questions .question.open .arrow figure {
  background-color: #345dff;
  box-shadow: none;
  transform: rotate(0deg);
}
#root .faqs .questions-category .questions .question.open .arrow figure svg {
  fill: #ffffff;
}

#root .homepage {
  margin-top: 75px;
  padding: 20px 12vw 130px 12vw;
  min-height: 100vh;
}
#root .homepage .homepage_buttons {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  gap: 36px;
  margin: 50px 0;
}
#root .homepage .homepage_buttons a {
  display: flex;
  align-items: center;
  gap: 30px;
  background-color: #ffffff;
  box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.2509803922);
  border-radius: 16px;
  padding: 12px;
  width: 100%;
}
#root .homepage .homepage_buttons a figure {
  height: 60px;
}
#root .homepage .homepage_buttons a figure svg {
  width: 100%;
  height: 100%;
}
#root .homepage .homepage_buttons a figure img {
  width: 100%;
  height: 100%;
}
#root .homepage .homepage_buttons a p.h_8, #root .homepage .homepage_buttons a .alertify .ajs-dialog p.ajs-header, .alertify .ajs-dialog #root .homepage .homepage_buttons a p.ajs-header {
  color: #000000;
}
#root .homepage .premium_discover {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  width: calc(50% - 24px);
  padding: 16px;
  border-radius: 24px;
  background-color: #ffffff;
  box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.2509803922);
  margin-top: 65px;
}
#root .homepage .premium_discover figure {
  width: 100%;
  height: 120px;
  border-radius: 12px;
  overflow: hidden;
}
#root .homepage .premium_discover figure img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
#root .homepage .premium_discover .premium_discover_content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
#root .homepage .premium_discover .premium_discover_content .h_7 span {
  margin-right: 8px;
}
#root .homepage .premium_discover .premium_discover_content .b_1 span, #root .homepage .premium_discover .premium_discover_content .alertify .ajs-dialog .ajs-body .ajs-content span, .alertify .ajs-dialog .ajs-body #root .homepage .premium_discover .premium_discover_content .ajs-content span {
  margin-left: 8px;
}
#root .homepage .premium_discover .background_btn.h_8, #root .homepage .premium_discover .alertify .ajs-dialog .background_btn.ajs-header, .alertify .ajs-dialog #root .homepage .premium_discover .background_btn.ajs-header {
  border-radius: 1500px;
  padding: 12px 36px;
}
#root .homepage-2 .first {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
}
#root .homepage-2 .first .img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}
#root .homepage-2 .first .img .filter {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(51, 51, 51, 0.4);
}
#root .homepage-2 .first .img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
#root .homepage-2 .first .txt {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: #ffffff;
}
#root .homepage-2 .first .txt .background_btn {
  width: 20vw;
  margin-top: 50px;
}
#root .homepage-2 .rewards {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1a4573;
  padding: 150px 90px;
  gap: 80px;
}
#root .homepage-2 .rewards .content {
  flex: 1 1 100%;
}
#root .homepage-2 .rewards .content .h_7 {
  color: #ffffff;
}
#root .homepage-2 .rewards .content .background_btn {
  background-color: #ffffff;
  color: #1a4573;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 30px;
}
#root .homepage-2 .rewards figure {
  flex: 1 1 100%;
}
#root .homepage-2 .rewards figure img {
  width: 100%;
  height: 100%;
}
#root .homepage-2 .memberships {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 30px;
  padding: 150px 90px;
}
#root .homepage-2 .memberships .background_btn {
  background: #1a4573;
}
#root .homepage-2 .memberships .membership {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 25px;
  border: 3px solid #1a4573;
  border-radius: 16px;
  padding: 24px;
  flex: 1 1 calc(33.3333333333% - 30px);
}
#root .homepage-2 .memberships .membership .hood {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 5px;
}
#root .homepage-2 .memberships .membership .hood .b_1, #root .homepage-2 .memberships .membership .hood .alertify .ajs-dialog .ajs-body .ajs-content, .alertify .ajs-dialog .ajs-body #root .homepage-2 .memberships .membership .hood .ajs-content {
  color: #1a4573;
  font-weight: 500;
}
#root .homepage-2 .memberships .membership .hood p.b_1, #root .homepage-2 .memberships .membership .hood .alertify .ajs-dialog .ajs-body p.ajs-content, .alertify .ajs-dialog .ajs-body #root .homepage-2 .memberships .membership .hood p.ajs-content {
  color: #000000;
  font-weight: 500;
}
#root .homepage-2 .memberships .free-plan {
  display: flex;
  gap: 30px;
}
#root .homepage-2 .memberships .free-plan .membership {
  flex: 1 1 calc(66.6666666667% - 30px);
}
#root .homepage-2 .memberships .free-plan .how-to-play {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  background-color: #1a4573;
  padding: 24px;
  border: 3px solid #1a4573;
  border-radius: 12px;
  flex: 1 1 calc(33.3333333333% - 30px);
}
#root .homepage-2 .memberships .free-plan .how-to-play .b_1, #root .homepage-2 .memberships .free-plan .how-to-play .alertify .ajs-dialog .ajs-body .ajs-content, .alertify .ajs-dialog .ajs-body #root .homepage-2 .memberships .free-plan .how-to-play .ajs-content {
  width: 80%;
  text-align: center;
  color: #ffffff;
  font-weight: 500;
}
#root .homepage-2 .memberships .free-plan .how-to-play .background_btn {
  width: 100%;
  background-color: #ffffff;
  color: #000000;
  font-weight: 400;
}
#root .homepage-2 .how-to {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 150px 90px;
}
#root .homepage-2 .how-to .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  flex: 1 1 100%;
}
#root .homepage-2 .how-to .content .h_8, #root .homepage-2 .how-to .content .alertify .ajs-dialog .ajs-header, .alertify .ajs-dialog #root .homepage-2 .how-to .content .ajs-header {
  text-align: center;
}
#root .coming-soon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #113357;
  gap: 30px;
}
#root .coming-soon .h_1 {
  color: #ffffff;
}
#root .coming-soon .h_5 {
  text-align: center;
  width: 35%;
  color: #ffffff;
}
#root .coming-soon .outline_btn, #root .coming-soon .alertify .ajs-dialog .ajs-footer .ajs-ok, .alertify .ajs-dialog .ajs-footer #root .coming-soon .ajs-ok {
  color: #ffffff;
  background: transparent;
  border-color: #ffffff;
}

#root .leaderboard_page {
  margin-top: 75px;
  padding: 100px 12vw;
}
#root .leaderboard_page h5.h_5.hood {
  text-align: center;
  margin-bottom: 30px;
}
#root .leaderboard_page .info_leaderboard {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  gap: 40px;
}
#root .leaderboard_page .info_leaderboard .total, #root .leaderboard_page .info_leaderboard .remaining_time {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  flex: 1 1 calc((100% - 80px) / 5);
  border-radius: 16px;
  padding: 16px;
  box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.2509803922);
}
#root .leaderboard_page .info_leaderboard .total figure, #root .leaderboard_page .info_leaderboard .remaining_time figure {
  height: 60px;
  background-color: red;
}
#root .leaderboard_page .info_leaderboard .total .content_total p.b_1, #root .leaderboard_page .info_leaderboard .remaining_time .content_total p.b_1, #root .leaderboard_page .info_leaderboard .total .content_total .alertify .ajs-dialog .ajs-body p.ajs-content, #root .leaderboard_page .info_leaderboard .remaining_time .content_total .alertify .ajs-dialog .ajs-body p.ajs-content, .alertify .ajs-dialog .ajs-body #root .leaderboard_page .info_leaderboard .total .content_total p.ajs-content, .alertify .ajs-dialog .ajs-body #root .leaderboard_page .info_leaderboard .remaining_time .content_total p.ajs-content {
  margin-bottom: 15px;
}
#root .leaderboard_page .info_leaderboard .total.total_player, #root .leaderboard_page .info_leaderboard .total_player.remaining_time {
  background-color: #dfffee;
  color: #1f9960;
}
#root .leaderboard_page .info_leaderboard .total.total_points, #root .leaderboard_page .info_leaderboard .total_points.remaining_time {
  background-color: #dfe5fe;
  color: #79819f;
}
#root .leaderboard_page .info_leaderboard .remaining_time {
  background-color: #fff5cd;
  flex: 1 1 calc((100% - 80px) / 5 * 3);
}
#root .leaderboard_page .info_leaderboard .remaining_time .remaining_time_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
}
#root .leaderboard_page .info_leaderboard .remaining_time .remaining_time_content h6.h_6 {
  text-transform: uppercase;
  color: #877a42;
}
#root .leaderboard_page .info_leaderboard .remaining_time .remaining_time_content p.h_6 {
  display: flex;
  align-items: stretch;
  gap: 8px;
  color: rgba(0, 0, 0, 0.5019607843);
}
#root .leaderboard_page .info_leaderboard .remaining_time .remaining_time_content p.h_6 .day,
#root .leaderboard_page .info_leaderboard .remaining_time .remaining_time_content p.h_6 .hours {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
#root .leaderboard_page .info_leaderboard .remaining_time .remaining_time_content p.h_6 .day span.h_8, #root .leaderboard_page .info_leaderboard .remaining_time .remaining_time_content p.h_6 .day .alertify .ajs-dialog span.ajs-header, .alertify .ajs-dialog #root .leaderboard_page .info_leaderboard .remaining_time .remaining_time_content p.h_6 .day span.ajs-header,
#root .leaderboard_page .info_leaderboard .remaining_time .remaining_time_content p.h_6 .hours span.h_8,
#root .leaderboard_page .info_leaderboard .remaining_time .remaining_time_content p.h_6 .hours .alertify .ajs-dialog span.ajs-header,
.alertify .ajs-dialog #root .leaderboard_page .info_leaderboard .remaining_time .remaining_time_content p.h_6 .hours span.ajs-header {
  color: #bbbbbb;
}
#root .leaderboard_page .info_leaderboard .remaining_time p.b_1, #root .leaderboard_page .info_leaderboard .remaining_time .alertify .ajs-dialog .ajs-body p.ajs-content, .alertify .ajs-dialog .ajs-body #root .leaderboard_page .info_leaderboard .remaining_time p.ajs-content {
  border-top: 1px solid rgba(0, 0, 0, 0.2509803922);
  padding-top: 10px;
  color: #877a42;
}
#root .leaderboard_page .leaderboard_sect {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  margin-top: 50px;
}
#root .leaderboard_page .leaderboard_sect .h_7.hood {
  text-align: center;
}
#root .leaderboard_page .leaderboard_sect .h_7.hood span {
  margin-right: 10px;
}
#root .leaderboard_page .leaderboard_sect .filter {
  width: 100%;
}
#root .leaderboard_page .leaderboard_sect .leaderboard {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
#root .leaderboard_page .leaderboard_sect .leaderboard .user_queue {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}
#root .leaderboard_page .leaderboard_sect .leaderboard .user_queue span.dot {
  width: 15px;
  height: 15px;
  background-color: #8e8e8e;
  border-radius: 50%;
}
#root .leaderboard_page .leaderboard_sect .outline_btn, #root .leaderboard_page .leaderboard_sect .alertify .ajs-dialog .ajs-footer .ajs-ok, .alertify .ajs-dialog .ajs-footer #root .leaderboard_page .leaderboard_sect .ajs-ok {
  margin-top: 50px;
  width: -moz-max-content;
  width: max-content;
  border-radius: 1500px;
  padding: 2px 10px;
}
#root .leaderboard-dropdown {
  width: 20vw;
  margin-bottom: 20px;
}
#root .leaderboard-dropdown .select-trigger {
  padding: 10px;
}
#root .leaderboard-dropdown .select-trigger .content-drop {
  gap: 10px;
  justify-content: space-between;
}
#root .leaderboard-dropdown .select-trigger .content-drop .b_1, #root .leaderboard-dropdown .select-trigger .content-drop .alertify .ajs-dialog .ajs-body .ajs-content, .alertify .ajs-dialog .ajs-body #root .leaderboard-dropdown .select-trigger .content-drop .ajs-content {
  font-weight: 500;
  color: #4a4a4a;
}
#root .leaderboard-dropdown .select-trigger .arrow {
  padding: 0;
}
#root .leaderboard-dropdown .select-trigger .arrow svg {
  width: 16px;
  height: 16px;
  fill: #4a4a4a;
}
#root .leaderboard-dropdown .options .b_1, #root .leaderboard-dropdown .options .alertify .ajs-dialog .ajs-body .ajs-content, .alertify .ajs-dialog .ajs-body #root .leaderboard-dropdown .options .ajs-content {
  color: #4a4a4a;
}
#root .leaderboard_card, #root .leaderboard_king_card {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.2509803922);
  border-radius: 16px;
  padding: 12px;
}
#root .leaderboard_card .left, #root .leaderboard_king_card .left {
  display: flex;
  align-items: center;
  gap: 15px;
}
#root .leaderboard_card .left .queue, #root .leaderboard_king_card .left .queue {
  display: flex;
  align-items: center;
}
#root .leaderboard_card .left .queue p.b_1, #root .leaderboard_king_card .left .queue p.b_1, #root .leaderboard_card .left .queue .alertify .ajs-dialog .ajs-body p.ajs-content, #root .leaderboard_king_card .left .queue .alertify .ajs-dialog .ajs-body p.ajs-content, .alertify .ajs-dialog .ajs-body #root .leaderboard_card .left .queue p.ajs-content, .alertify .ajs-dialog .ajs-body #root .leaderboard_king_card .left .queue p.ajs-content {
  font-weight: 600;
  white-space: pre;
  font-family: monospace;
}
#root .leaderboard_card .left figure, #root .leaderboard_king_card .left figure {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.1019607843);
}
#root .leaderboard_card .left figure img, #root .leaderboard_king_card .left figure img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
#root .leaderboard_card .right, #root .leaderboard_king_card .right {
  display: flex;
  align-items: center;
  gap: 15px;
}
#root .leaderboard_card .right .point, #root .leaderboard_king_card .right .point {
  color: #345dff;
}
#root .leaderboard_card .right .point span, #root .leaderboard_king_card .right .point span {
  margin-right: 5px;
}
#root .leaderboard_card .right .report, #root .leaderboard_king_card .right .report {
  position: relative;
}
#root .leaderboard_card .right .report figure, #root .leaderboard_king_card .right .report figure {
  height: 20px;
  width: 20px;
  opacity: 0;
  transition: 300ms;
  cursor: pointer;
}
#root .leaderboard_card .right .report figure svg, #root .leaderboard_king_card .right .report figure svg {
  width: 100%;
  height: 100%;
  fill: rgba(0, 0, 0, 0.2509803922);
}
#root .leaderboard_card .right .report .content, #root .leaderboard_king_card .right .report .content {
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.2509803922);
  top: 0;
  right: 0;
  width: -moz-max-content;
  width: max-content;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 15px 10px;
  border-radius: 5px;
  display: none;
}
#root .leaderboard_card .right .report .content .b_1, #root .leaderboard_king_card .right .report .content .b_1, #root .leaderboard_card .right .report .content .alertify .ajs-dialog .ajs-body .ajs-content, #root .leaderboard_king_card .right .report .content .alertify .ajs-dialog .ajs-body .ajs-content, .alertify .ajs-dialog .ajs-body #root .leaderboard_card .right .report .content .ajs-content, .alertify .ajs-dialog .ajs-body #root .leaderboard_king_card .right .report .content .ajs-content {
  color: #5f5f5f;
  transition: 300ms;
}
#root .leaderboard_card .right .report .content .b_1:hover, #root .leaderboard_king_card .right .report .content .b_1:hover, #root .leaderboard_card .right .report .content .alertify .ajs-dialog .ajs-body .ajs-content:hover, #root .leaderboard_king_card .right .report .content .alertify .ajs-dialog .ajs-body .ajs-content:hover, .alertify .ajs-dialog .ajs-body #root .leaderboard_card .right .report .content .ajs-content:hover, .alertify .ajs-dialog .ajs-body #root .leaderboard_king_card .right .report .content .ajs-content:hover {
  color: #000000;
}
#root .leaderboard_card .right .report:hover .content, #root .leaderboard_king_card .right .report:hover .content {
  display: flex;
}
#root .leaderboard_card.my, #root .my.leaderboard_king_card {
  box-shadow: 0px 5px 16px 0px #345dff;
}
#root .leaderboard_card:hover .report figure, #root .leaderboard_king_card:hover .report figure {
  opacity: 1;
}
#root .leaderboard_king_card {
  width: calc((100% - 40px) / 3);
  align-items: flex-start;
}
#root .leaderboard_king_card .leaderboard_king_card_content {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 15px;
}
#root .leaderboard_king_card .leaderboard_king_card_content .left figure.queue,
#root .leaderboard_king_card .leaderboard_king_card_content .left figure.profile {
  width: 60px;
  height: 60px;
}
#root .leaderboard_king_card .leaderboard_king_card_content .left figure.queue {
  box-shadow: 0 0 0 0;
}
#root .leaderboard_king_card .leaderboard_king_card_content .left figure.profile img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
#root .leaderboard_king_card .leaderboard_king_card_content .right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0;
}
#root .leaderboard_king_card .leaderboard_king_card_content .right .h_8 span, #root .leaderboard_king_card .leaderboard_king_card_content .right .alertify .ajs-dialog .ajs-header span, .alertify .ajs-dialog #root .leaderboard_king_card .leaderboard_king_card_content .right .ajs-header span {
  margin-right: 5px;
}
#root .leaderboard_king_card .report {
  display: flex;
  justify-content: flex-end;
  position: relative;
}
#root .leaderboard_king_card .report figure {
  height: 20px;
  fill: rgba(0, 0, 0, 0.2509803922);
  opacity: 0;
  transition: 300ms;
  cursor: pointer;
}
#root .leaderboard_king_card .report figure svg {
  width: 100%;
  height: 100%;
}
#root .leaderboard_king_card .report .content {
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.2509803922);
  top: 0;
  right: 0;
  width: -moz-max-content;
  width: max-content;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 15px 10px;
  border-radius: 5px;
  display: none;
}
#root .leaderboard_king_card .report .content .b_1, #root .leaderboard_king_card .report .content .alertify .ajs-dialog .ajs-body .ajs-content, .alertify .ajs-dialog .ajs-body #root .leaderboard_king_card .report .content .ajs-content {
  color: #5f5f5f;
  transition: 300ms;
}
#root .leaderboard_king_card .report .content .b_1:hover, #root .leaderboard_king_card .report .content .alertify .ajs-dialog .ajs-body .ajs-content:hover, .alertify .ajs-dialog .ajs-body #root .leaderboard_king_card .report .content .ajs-content:hover {
  color: #000000;
}
#root .leaderboard_king_card .report:hover .content {
  display: flex;
}
#root .leaderboard_king_card.first {
  color: #908b79;
}
#root .leaderboard_king_card.second {
  color: #8a8a8a;
}
#root .leaderboard_king_card.thirth {
  color: #9b7255;
}
#root .leaderboard_king_card:hover .report figure {
  opacity: 1;
}

#root .leagues_page {
  margin-top: 75px;
  padding: 100px 12vw;
}
#root .leagues_page .hood_league {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}
#root .leagues_page .leagues {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  gap: 48px;
  position: relative;
}
#root .leagues_component .hood_league {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}
#root .leagues_component .hood_league a.outline_btn, #root .leagues_component .hood_league .alertify .ajs-dialog .ajs-footer a.ajs-ok, .alertify .ajs-dialog .ajs-footer #root .leagues_component .hood_league a.ajs-ok {
  padding: 2px 10px;
  border-radius: 16px;
  cursor: pointer;
}
#root .leagues_component .hood_league a.outline_btn .b_1, #root .leagues_component .hood_league .alertify .ajs-dialog .ajs-footer a.ajs-ok .b_1, .alertify .ajs-dialog .ajs-footer #root .leagues_component .hood_league a.ajs-ok .b_1, #root .leagues_component .hood_league a.outline_btn .alertify .ajs-dialog .ajs-body .ajs-content, .alertify .ajs-dialog .ajs-body #root .leagues_component .hood_league a.outline_btn .ajs-content, #root .leagues_component .hood_league .alertify .ajs-dialog .ajs-footer a.ajs-ok .ajs-body .ajs-content, #root .leagues_component .hood_league .alertify .ajs-dialog .ajs-body .ajs-footer a.ajs-ok .ajs-content, .alertify .ajs-dialog .ajs-footer #root .leagues_component .hood_league a.ajs-ok .ajs-body .ajs-content, .alertify .ajs-dialog .ajs-body .ajs-footer #root .leagues_component .hood_league a.ajs-ok .ajs-content {
  font-weight: 500;
}
#root .leagues_component .leagues {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  gap: 48px;
  position: relative;
}
#root .league_component {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1 calc(25% - 48px);
  box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.2509803922);
  padding: 24px;
  border-radius: 24px;
  background-color: #ffffff;
  gap: 8px;
  position: relative;
  overflow: hidden;
}
#root .league_component .block {
  display: none;
  position: absolute;
  top: 20px;
  left: 20px;
}
#root .league_component .block figure {
  width: 24px;
}
#root .league_component .block figure svg {
  width: 100%;
  height: 100%;
  fill: #ffdb43;
  stroke-width: 5%;
  stroke: rgba(0, 0, 0, 0.2509803922);
}
#root .league_component .content_league {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}
#root .league_component .content_league figure {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.2509803922);
}
#root .league_component .content_league figure img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
#root .league_component .content_league .h_8, #root .league_component .content_league .alertify .ajs-dialog .ajs-header, .alertify .ajs-dialog #root .league_component .content_league .ajs-header {
  text-align: center;
}
#root .league_component .content_league .b_2 {
  text-align: center;
}
#root .league_component .bottom .b_2 {
  text-align: center;
  color: #1f9960;
  font-weight: 500;
  margin-bottom: 10px;
}
#root .league_component .bottom .background_btn {
  width: 100%;
  padding: 8px;
  border-radius: 150px;
}
#root .league_component.premium .block {
  display: inline-block;
}

#root .register-login-page .content.login .form .phone-mail-username .phone {
  position: relative;
}
#root .register-login-page .content.login .form .phone-mail-username .phone input {
  width: 100%;
}
#root .register-login-page .content.login .form .phone-mail-username .mail {
  position: relative;
}
#root .register-login-page .content.login .form .phone-mail-username .username {
  position: relative;
}
#root .register-login-page .content.login .form .phone-mail-username .input {
  width: 100%;
}
#root .register-login-page .content.login .form .password {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 32px 0;
  position: relative;
}
#root .register-login-page .content.login .form .remember-forget {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#root .register-login-page .content.login .form .remember-forget .b_2 {
  color: #345dff;
}
#root .register-login-page .content.login .form .remember-forget .remember-me .custom-checkbox {
  padding-left: 0;
  padding-right: 20px;
}
#root .register-login-page .content.login .form .remember-forget .remember-me .custom-checkbox .checkmark {
  right: 0;
  left: auto;
}
#root .register-login-page .content.login .form .background_btn {
  width: 100%;
  margin: 68px 0 24px 0;
}
#root .register-login-page .content.login .form .no-account {
  color: #345dff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
#root .register-login-page .content.login .forgot-password .b_1.desc, #root .register-login-page .content.login .forgot-password .alertify .ajs-dialog .ajs-body .desc.ajs-content, .alertify .ajs-dialog .ajs-body #root .register-login-page .content.login .forgot-password .desc.ajs-content {
  margin-top: 12px;
}
#root .register-login-page .content.login .forgot-password form .phone-mail {
  margin: 130px 0 190px 0;
}
#root .register-login-page .content.login .forgot-password form .phone-mail .links {
  display: flex;
  gap: 24px;
  align-items: center;
  margin-bottom: 15px;
}
#root .register-login-page .content.login .forgot-password form .phone-mail .links .b_3 {
  width: 100%;
  border: 3px solid #345dff;
  border-radius: 12px;
  color: #345dff;
  padding: 12px;
  background-color: #ffffff;
  text-align: center;
}
#root .register-login-page .content.login .forgot-password form .phone-mail .links .b_3.active {
  background-color: #345dff;
  color: #ffffff;
}
#root .register-login-page .content.login .forgot-password form .phone-mail .phone {
  position: relative;
}
#root .register-login-page .content.login .forgot-password form .phone-mail .phone input {
  width: 100%;
}
#root .register-login-page .content.login .forgot-password form .phone-mail .mail {
  position: relative;
}
#root .register-login-page .content.login .forgot-password form .phone-mail .input {
  width: 100%;
}
#root .register-login-page .content.login .forgot-password form .phone-mail .terms {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  height: 0;
  overflow: hidden;
  transition: 300ms all;
}
#root .register-login-page .content.login .forgot-password form .phone-mail .terms .term {
  display: flex;
  align-items: center;
  gap: 5px;
}
#root .register-login-page .content.login .forgot-password form .phone-mail .terms .term figure {
  width: 16px;
  height: 16px;
}
#root .register-login-page .content.login .forgot-password form .phone-mail .terms .term figure svg {
  width: 100%;
  height: 100%;
}
#root .register-login-page .content.login .forgot-password form .phone-mail .terms .term figure svg.xmark {
  fill: #d00416;
}
#root .register-login-page .content.login .forgot-password form .phone-mail .terms .term figure svg.correct {
  fill: #1fc16b;
}
#root .register-login-page .content.login .forgot-password form .phone-mail .terms .term .b_4.wrong, #root .register-login-page .content.login .forgot-password form .phone-mail .terms .term .alertify .ajs-dialog .ajs-footer .wrong.ajs-ok, .alertify .ajs-dialog .ajs-footer #root .register-login-page .content.login .forgot-password form .phone-mail .terms .term .wrong.ajs-ok {
  color: #d00416;
}
#root .register-login-page .content.login .forgot-password form .phone-mail .terms .term .b_4.correct, #root .register-login-page .content.login .forgot-password form .phone-mail .terms .term .alertify .ajs-dialog .ajs-footer .correct.ajs-ok, .alertify .ajs-dialog .ajs-footer #root .register-login-page .content.login .forgot-password form .phone-mail .terms .term .correct.ajs-ok {
  color: #1fc16b;
}
#root .register-login-page .content.login .forgot-password form .phone-mail .terms.active {
  height: -moz-max-content;
  height: max-content;
}
#root .register-login-page .content.login .forgot-password form .background_btn {
  width: 100%;
}
#root .register-login-page .content.login .forgot-password .verify-code-page {
  margin-top: 50px;
}
#root .register-login-page .content.login .forgot-password .verify-code-page .wrong-txt {
  display: none;
}
#root .register-login-page .content.login .forgot-password .verify-code-page .wrong-txt .h_8, #root .register-login-page .content.login .forgot-password .verify-code-page .wrong-txt .alertify .ajs-dialog .ajs-header, .alertify .ajs-dialog #root .register-login-page .content.login .forgot-password .verify-code-page .wrong-txt .ajs-header {
  color: #d00416;
  text-align: center;
  margin: 20px;
}
#root .register-login-page .content.login .forgot-password .verify-code-page .content-verify {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  margin-top: 25px;
  margin-bottom: 75px;
}
#root .register-login-page .content.login .forgot-password .verify-code-page .content-verify .content-verify-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}
#root .register-login-page .content.login .forgot-password .verify-code-page .content-verify .content-verify-inner .b_1, #root .register-login-page .content.login .forgot-password .verify-code-page .content-verify .content-verify-inner .alertify .ajs-dialog .ajs-body .ajs-content, .alertify .ajs-dialog .ajs-body #root .register-login-page .content.login .forgot-password .verify-code-page .content-verify .content-verify-inner .ajs-content {
  width: 95%;
  text-align: center;
}
#root .register-login-page .content.login .forgot-password .verify-code-page .content-verify .content-verify-inner .links {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
#root .register-login-page .content.login .forgot-password .verify-code-page .content-verify .content-verify-inner .links .b_2 {
  color: #345dff;
  text-align: center;
  background: none;
}
#root .register-login-page .content.login .forgot-password .verify-code-page .content-verify .content-verify-inner .links .b_2:disabled, #root .register-login-page .content.login .forgot-password .verify-code-page .content-verify .content-verify-inner .links .b_2[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
#root .register-login-page .content.login .forgot-password .verify-code-page .content-verify .counter {
  text-align: center;
  color: #d00416;
}
#root .register-login-page .content.login .forgot-password .verify-code-page .background_btn {
  width: 100%;
}
#root .register-login-page .content.login .forgot-password .verify-code-page.wrong .verify-code-inputs .vi__container .vi__character {
  box-shadow: 0px 5px 16px 0px rgba(208, 4, 22, 0.5098039216);
}
#root .register-login-page .content.login .forgot-password .verify-code-page.wrong .verify-code-inputs .vi__container .vi__character:where(.vi__character--selected) {
  color: #fb3748;
}
#root .register-login-page .content.login .forgot-password .verify-code-page.wrong .wrong-txt {
  display: block;
}
#root .register-login-page .content.login .forgot-password .set-password p.b_1, #root .register-login-page .content.login .forgot-password .set-password .alertify .ajs-dialog .ajs-body p.ajs-content, .alertify .ajs-dialog .ajs-body #root .register-login-page .content.login .forgot-password .set-password p.ajs-content {
  margin-top: 12px;
}
#root .register-login-page .content.login .forgot-password .set-password .passwords {
  display: flex;
  flex-direction: column;
  gap: 75px;
  margin: 95px 0 190px 0;
}
#root .register-login-page .content.login .forgot-password .set-password .passwords .password,
#root .register-login-page .content.login .forgot-password .set-password .passwords .password-again {
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;
}
#root .register-login-page .content.login .forgot-password .set-password .passwords .password .b_4, #root .register-login-page .content.login .forgot-password .set-password .passwords .password .alertify .ajs-dialog .ajs-footer .ajs-ok, .alertify .ajs-dialog .ajs-footer #root .register-login-page .content.login .forgot-password .set-password .passwords .password .ajs-ok,
#root .register-login-page .content.login .forgot-password .set-password .passwords .password-again .b_4,
#root .register-login-page .content.login .forgot-password .set-password .passwords .password-again .alertify .ajs-dialog .ajs-footer .ajs-ok,
.alertify .ajs-dialog .ajs-footer #root .register-login-page .content.login .forgot-password .set-password .passwords .password-again .ajs-ok {
  color: #d00416;
}
#root .register-login-page .content.login .forgot-password .set-password .passwords .terms {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  height: 0;
  overflow: hidden;
  transition: 300ms all;
}
#root .register-login-page .content.login .forgot-password .set-password .passwords .terms .term {
  display: flex;
  align-items: center;
  gap: 5px;
}
#root .register-login-page .content.login .forgot-password .set-password .passwords .terms .term figure {
  width: 16px;
  height: 16px;
}
#root .register-login-page .content.login .forgot-password .set-password .passwords .terms .term figure svg {
  width: 100%;
  height: 100%;
}
#root .register-login-page .content.login .forgot-password .set-password .passwords .terms .term figure svg.xmark {
  fill: #d00416;
}
#root .register-login-page .content.login .forgot-password .set-password .passwords .terms .term figure svg.correct {
  fill: #1fc16b;
}
#root .register-login-page .content.login .forgot-password .set-password .passwords .terms .term .b_4.wrong, #root .register-login-page .content.login .forgot-password .set-password .passwords .terms .term .alertify .ajs-dialog .ajs-footer .wrong.ajs-ok, .alertify .ajs-dialog .ajs-footer #root .register-login-page .content.login .forgot-password .set-password .passwords .terms .term .wrong.ajs-ok {
  color: #d00416;
}
#root .register-login-page .content.login .forgot-password .set-password .passwords .terms .term .b_4.correct, #root .register-login-page .content.login .forgot-password .set-password .passwords .terms .term .alertify .ajs-dialog .ajs-footer .correct.ajs-ok, .alertify .ajs-dialog .ajs-footer #root .register-login-page .content.login .forgot-password .set-password .passwords .terms .term .correct.ajs-ok {
  color: #1fc16b;
}
#root .register-login-page .content.login .forgot-password .set-password .passwords .terms.active {
  height: -moz-max-content;
  height: max-content;
}

#root .matches_page {
  margin-top: 75px;
  padding: 20px 12vw 130px 12vw;
  position: relative;
  min-height: 100vh;
}
#root .matches_page .matches_content .h_5.hood {
  text-align: center;
  margin: 25px 0 70px 0;
}
#root .matches_page .matches_content .matches {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 48px;
}
#root .matches_page .matches_content.no {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  margin-bottom: 50px;
  color: rgba(0, 0, 0, 0.5019607843);
}
#root .matches_page .matches_content.no .h_3 {
  text-align: center;
  width: 75%;
}
#root .matches_page .matches_content.no .h_6 {
  text-align: center;
}
#root .matches_page .matches_content.no figure {
  width: 28px;
}
#root .matches_page .matches_content.no figure svg {
  width: 100%;
  height: 100%;
  fill: rgba(0, 0, 0, 0.5019607843);
}
#root .favorite_match {
  margin-top: 60px;
}
#root .favorite_match .hood_match {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}
#root .favorite_match .hood_match a.outline_btn, #root .favorite_match .hood_match .alertify .ajs-dialog .ajs-footer a.ajs-ok, .alertify .ajs-dialog .ajs-footer #root .favorite_match .hood_match a.ajs-ok {
  padding: 2px 10px;
  border-radius: 16px;
  cursor: pointer;
}
#root .favorite_match .hood_match a.outline_btn .b_1, #root .favorite_match .hood_match .alertify .ajs-dialog .ajs-footer a.ajs-ok .b_1, .alertify .ajs-dialog .ajs-footer #root .favorite_match .hood_match a.ajs-ok .b_1, #root .favorite_match .hood_match a.outline_btn .alertify .ajs-dialog .ajs-body .ajs-content, .alertify .ajs-dialog .ajs-body #root .favorite_match .hood_match a.outline_btn .ajs-content, #root .favorite_match .hood_match .alertify .ajs-dialog .ajs-footer a.ajs-ok .ajs-body .ajs-content, #root .favorite_match .hood_match .alertify .ajs-dialog .ajs-body .ajs-footer a.ajs-ok .ajs-content, .alertify .ajs-dialog .ajs-footer #root .favorite_match .hood_match a.ajs-ok .ajs-body .ajs-content, .alertify .ajs-dialog .ajs-body .ajs-footer #root .favorite_match .hood_match a.ajs-ok .ajs-content {
  font-weight: 500;
}
#root .favorite_match .matches {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  gap: 48px;
  position: relative;
}

#root .package_detail {
  margin-top: 75px;
  padding: 30px 12vw 100px 12vw;
  min-height: 100vh;
}
#root .package_detail .hood {
  display: flex;
  flex-direction: column;
  gap: 25px;
  text-align: center;
  margin-bottom: 25px;
}
#root .package_detail .matches {
  display: flex;
  flex-wrap: wrap;
  gap: 36px;
  width: 100%;
  margin-bottom: 60px;
}

#root .packages_page {
  margin-top: 75px;
  padding: 30px 12vw 100px 12vw;
  min-height: 100vh;
}
#root .packages_page .packages_hood {
  display: flex;
  flex-direction: column;
  gap: 25px;
  text-align: center;
  margin-bottom: 25px;
}
#root .packages_page .packages_filter {
  margin-bottom: 40px;
}
#root .packages_page .packages_filter .filter {
  display: flex;
  align-items: stretch;
  gap: 15px;
  position: relative;
}
#root .packages_page .packages_filter .filter .week,
#root .packages_page .packages_filter .filter .status {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 5px;
}
#root .packages_page .package_items {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 35px;
}
#root .package_item {
  background-color: #ffffff;
  border: 3px solid rgba(0, 0, 0, 0.5019607843);
  box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.2509803922);
  border-radius: 16px;
}
#root .package_item a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
}
#root .package_item a .package_info {
  display: flex;
  align-items: center;
  gap: 70px;
}
#root .package_item a .package_info .b_1, #root .package_item a .package_info .alertify .ajs-dialog .ajs-body .ajs-content, .alertify .ajs-dialog .ajs-body #root .package_item a .package_info .ajs-content {
  color: #000000;
}
#root .package_item a .package_info .b_1.id, #root .package_item a .package_info .alertify .ajs-dialog .ajs-body .id.ajs-content, .alertify .ajs-dialog .ajs-body #root .package_item a .package_info .id.ajs-content, #root .package_item a .package_info .b_1.correct_prediction, #root .package_item a .package_info .alertify .ajs-dialog .ajs-body .correct_prediction.ajs-content, .alertify .ajs-dialog .ajs-body #root .package_item a .package_info .correct_prediction.ajs-content {
  color: #345dff;
}
#root .package_item a .package_info .b_1.point, #root .package_item a .package_info .alertify .ajs-dialog .ajs-body .point.ajs-content, .alertify .ajs-dialog .ajs-body #root .package_item a .package_info .point.ajs-content {
  color: #1f9960;
  font-weight: 500;
}
#root .package_item a figure {
  width: 15px;
}
#root .package_item a figure svg {
  width: 100%;
  height: 100%;
  fill: #000000;
}
#root .package_item.active {
  border-color: #1f9960;
}

#root .prediction_page {
  margin-top: 75px;
  min-height: 100vh;
  padding: 80px 12vw 135px 12vw;
}
#root .prediction_page .hood.h_5 {
  text-align: center;
}
#root .prediction_page .hood.h_8, #root .prediction_page .alertify .ajs-dialog .hood.ajs-header, .alertify .ajs-dialog #root .prediction_page .hood.ajs-header {
  margin-top: 25px;
  text-align: center;
}
#root .prediction_page .prediction_page_content {
  display: flex;
  flex-direction: column;
  gap: 60px;
}
#root .prediction_page .prediction_page_content .right_to_and_points {
  display: flex;
  gap: 36px;
  justify-content: space-between;
  margin-top: 40px;
  width: 100%;
}
#root .prediction_page .prediction_page_content .right_to_and_points .right_to_predictions,
#root .prediction_page .prediction_page_content .right_to_and_points .total_points {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.2509803922);
  border-radius: 16px;
  padding: 12px;
  width: calc(50% - 18px);
}
#root .prediction_page .prediction_page_content .right_to_and_points .right_to_predictions div.hood,
#root .prediction_page .prediction_page_content .right_to_and_points .total_points div.hood {
  display: flex;
  align-items: center;
  gap: 10px;
}
#root .prediction_page .prediction_page_content .right_to_and_points .right_to_predictions div.hood figure,
#root .prediction_page .prediction_page_content .right_to_and_points .total_points div.hood figure {
  height: 35px;
  display: flex;
}
#root .prediction_page .prediction_page_content .right_to_and_points .right_to_predictions div.hood figure img,
#root .prediction_page .prediction_page_content .right_to_and_points .total_points div.hood figure img {
  width: 100%;
  height: 100%;
}
#root .prediction_page .prediction_page_content .right_to_and_points .right_to_predictions div.hood h6.h_8, #root .prediction_page .prediction_page_content .right_to_and_points .right_to_predictions div.hood .alertify .ajs-dialog h6.ajs-header, .alertify .ajs-dialog #root .prediction_page .prediction_page_content .right_to_and_points .right_to_predictions div.hood h6.ajs-header,
#root .prediction_page .prediction_page_content .right_to_and_points .total_points div.hood h6.h_8,
#root .prediction_page .prediction_page_content .right_to_and_points .total_points div.hood .alertify .ajs-dialog h6.ajs-header,
.alertify .ajs-dialog #root .prediction_page .prediction_page_content .right_to_and_points .total_points div.hood h6.ajs-header {
  display: flex;
  align-items: flex-start;
  gap: 5px;
}
#root .prediction_page .prediction_page_content .right_to_and_points .right_to_predictions div.hood h6.h_8 .info, #root .prediction_page .prediction_page_content .right_to_and_points .right_to_predictions div.hood .alertify .ajs-dialog h6.ajs-header .info, .alertify .ajs-dialog #root .prediction_page .prediction_page_content .right_to_and_points .right_to_predictions div.hood h6.ajs-header .info,
#root .prediction_page .prediction_page_content .right_to_and_points .total_points div.hood h6.h_8 .info,
#root .prediction_page .prediction_page_content .right_to_and_points .total_points div.hood .alertify .ajs-dialog h6.ajs-header .info,
.alertify .ajs-dialog #root .prediction_page .prediction_page_content .right_to_and_points .total_points div.hood h6.ajs-header .info {
  position: relative;
  cursor: pointer;
}
#root .prediction_page .prediction_page_content .right_to_and_points .right_to_predictions div.hood h6.h_8 .info figure, #root .prediction_page .prediction_page_content .right_to_and_points .right_to_predictions div.hood .alertify .ajs-dialog h6.ajs-header .info figure, .alertify .ajs-dialog #root .prediction_page .prediction_page_content .right_to_and_points .right_to_predictions div.hood h6.ajs-header .info figure,
#root .prediction_page .prediction_page_content .right_to_and_points .total_points div.hood h6.h_8 .info figure,
#root .prediction_page .prediction_page_content .right_to_and_points .total_points div.hood .alertify .ajs-dialog h6.ajs-header .info figure,
.alertify .ajs-dialog #root .prediction_page .prediction_page_content .right_to_and_points .total_points div.hood h6.ajs-header .info figure {
  width: 16px;
  height: 16px;
  padding: 2px;
  border-radius: 50%;
  border: 1px solid #777777;
}
#root .prediction_page .prediction_page_content .right_to_and_points .right_to_predictions div.hood h6.h_8 .info figure svg, #root .prediction_page .prediction_page_content .right_to_and_points .right_to_predictions div.hood .alertify .ajs-dialog h6.ajs-header .info figure svg, .alertify .ajs-dialog #root .prediction_page .prediction_page_content .right_to_and_points .right_to_predictions div.hood h6.ajs-header .info figure svg,
#root .prediction_page .prediction_page_content .right_to_and_points .total_points div.hood h6.h_8 .info figure svg,
#root .prediction_page .prediction_page_content .right_to_and_points .total_points div.hood .alertify .ajs-dialog h6.ajs-header .info figure svg,
.alertify .ajs-dialog #root .prediction_page .prediction_page_content .right_to_and_points .total_points div.hood h6.ajs-header .info figure svg {
  width: 100%;
  height: 100%;
  fill: #777777;
}
#root .prediction_page .prediction_page_content .right_to_and_points .right_to_predictions div.hood h6.h_8 .info .modal, #root .prediction_page .prediction_page_content .right_to_and_points .right_to_predictions div.hood .alertify .ajs-dialog h6.ajs-header .info .modal, .alertify .ajs-dialog #root .prediction_page .prediction_page_content .right_to_and_points .right_to_predictions div.hood h6.ajs-header .info .modal,
#root .prediction_page .prediction_page_content .right_to_and_points .total_points div.hood h6.h_8 .info .modal,
#root .prediction_page .prediction_page_content .right_to_and_points .total_points div.hood .alertify .ajs-dialog h6.ajs-header .info .modal,
.alertify .ajs-dialog #root .prediction_page .prediction_page_content .right_to_and_points .total_points div.hood h6.ajs-header .info .modal {
  position: absolute;
  bottom: 16px;
  left: 16px;
  padding: 14px 8px;
  border: 1px solid #000000;
  background: #ffffff;
  border-radius: 4px 4px 4px 0;
  width: -moz-max-content;
  width: max-content;
  display: none;
}
#root .prediction_page .prediction_page_content .right_to_and_points .right_to_predictions div.hood h6.h_8 .info:hover .modal, #root .prediction_page .prediction_page_content .right_to_and_points .right_to_predictions div.hood .alertify .ajs-dialog h6.ajs-header .info:hover .modal, .alertify .ajs-dialog #root .prediction_page .prediction_page_content .right_to_and_points .right_to_predictions div.hood h6.ajs-header .info:hover .modal,
#root .prediction_page .prediction_page_content .right_to_and_points .total_points div.hood h6.h_8 .info:hover .modal,
#root .prediction_page .prediction_page_content .right_to_and_points .total_points div.hood .alertify .ajs-dialog h6.ajs-header .info:hover .modal,
.alertify .ajs-dialog #root .prediction_page .prediction_page_content .right_to_and_points .total_points div.hood h6.ajs-header .info:hover .modal {
  display: inline-block;
}
#root .prediction_page .prediction_page_content .predictions_and_settings {
  display: flex;
  flex-direction: column;
}
#root .prediction_page .prediction_page_content .predictions_and_settings .predictions {
  display: flex;
  flex-wrap: wrap;
  gap: 36px;
  width: 100%;
  margin-bottom: 60px;
}
#root .prediction_page .prediction_page_content .predictions_and_settings .predictions .no_prediction {
  display: flex;
  flex-direction: column;
  gap: 60px;
}
#root .prediction_page .prediction_page_content .predictions_and_settings .predictions .no_prediction .background_btn.b_3 {
  font-weight: 600;
  padding: 18px;
  border-radius: 1500px;
}
#root .prediction_page .prediction_page_content .predictions_and_settings .background_btn {
  border-radius: 1500px;
}

#root .register-login-page .content.register .form .mail-username {
  display: flex;
  justify-content: space-between;
  gap: 50px;
}
#root .register-login-page .content.register .form .mail-username .input {
  width: 100%;
}
#root .register-login-page .content.register .form .mail-username .mail-phone {
  flex: 1 1 100%;
}
#root .register-login-page .content.register .form .mail-username .mail-phone .phone {
  position: relative;
}
#root .register-login-page .content.register .form .mail-username .mail-phone .phone .phone-input #phone {
  width: 100%;
}
#root .register-login-page .content.register .form .mail-username .mail-phone .mail {
  position: relative;
}
#root .register-login-page .content.register .form .mail-username .username {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1 1 100%;
  gap: 7px;
  position: relative;
}
#root .register-login-page .content.register .form .mail-username .username .label {
  display: flex;
  align-items: center;
  gap: 5px;
}
#root .register-login-page .content.register .form .mail-username .username .label .info {
  position: relative;
}
#root .register-login-page .content.register .form .mail-username .username .label .info figure {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  padding: 2px;
  border-radius: 50%;
  border: 1px solid #777777;
  cursor: pointer;
}
#root .register-login-page .content.register .form .mail-username .username .label .info figure svg {
  width: 100%;
  height: 100%;
  fill: #777777;
}
#root .register-login-page .content.register .form .mail-username .username .label .info .modal {
  position: absolute;
  bottom: 16px;
  left: 16px;
  padding: 14px 8px;
  border: 1px solid #000000;
  background: #ffffff;
  border-radius: 4px 4px 4px 0;
  width: -moz-max-content;
  width: max-content;
  display: none;
}
#root .register-login-page .content.register .form .mail-username .username .label .info:hover .modal {
  display: block;
}
#root .register-login-page .content.register .form .passwords {
  display: flex;
  flex-direction: column;
  gap: 75px;
  margin: 30px 0 50px 0;
}
#root .register-login-page .content.register .form .passwords .password,
#root .register-login-page .content.register .form .passwords .password-again {
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;
}
#root .register-login-page .content.register .form .passwords .password .b_4, #root .register-login-page .content.register .form .passwords .password .alertify .ajs-dialog .ajs-footer .ajs-ok, .alertify .ajs-dialog .ajs-footer #root .register-login-page .content.register .form .passwords .password .ajs-ok,
#root .register-login-page .content.register .form .passwords .password-again .b_4,
#root .register-login-page .content.register .form .passwords .password-again .alertify .ajs-dialog .ajs-footer .ajs-ok,
.alertify .ajs-dialog .ajs-footer #root .register-login-page .content.register .form .passwords .password-again .ajs-ok {
  color: #d00416;
}
#root .register-login-page .content.register .form .agreements {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}
#root .register-login-page .content.register .form .agreements .agreement .b_2 {
  color: #345dff;
}
#root .register-login-page .content.register .form .agreements .agreement .b_2.wrong {
  color: #d00416;
}
#root .register-login-page .content.register .form .background_btn {
  margin-top: 12px;
  width: 100%;
}
#root .register-login-page .content.register .verify-code-page {
  margin-top: 50px;
}
#root .register-login-page .content.register .verify-code-page .wrong-txt {
  display: none;
}
#root .register-login-page .content.register .verify-code-page .wrong-txt .h_8, #root .register-login-page .content.register .verify-code-page .wrong-txt .alertify .ajs-dialog .ajs-header, .alertify .ajs-dialog #root .register-login-page .content.register .verify-code-page .wrong-txt .ajs-header {
  color: #d00416;
  text-align: center;
  margin: 20px;
}
#root .register-login-page .content.register .verify-code-page .content-verify {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  margin-top: 25px;
  margin-bottom: 75px;
}
#root .register-login-page .content.register .verify-code-page .content-verify .content-verify-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}
#root .register-login-page .content.register .verify-code-page .content-verify .content-verify-inner .b_1, #root .register-login-page .content.register .verify-code-page .content-verify .content-verify-inner .alertify .ajs-dialog .ajs-body .ajs-content, .alertify .ajs-dialog .ajs-body #root .register-login-page .content.register .verify-code-page .content-verify .content-verify-inner .ajs-content {
  width: 80%;
  text-align: center;
}
#root .register-login-page .content.register .verify-code-page .content-verify .content-verify-inner .links {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
#root .register-login-page .content.register .verify-code-page .content-verify .content-verify-inner .links .b_2 {
  color: #345dff;
  text-align: center;
  background: none;
}
#root .register-login-page .content.register .verify-code-page .content-verify .content-verify-inner .links .b_2:disabled, #root .register-login-page .content.register .verify-code-page .content-verify .content-verify-inner .links .b_2[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
#root .register-login-page .content.register .verify-code-page .content-verify .counter {
  text-align: center;
  color: #d00416;
}
#root .register-login-page .content.register .verify-code-page .background_btn {
  width: 100%;
}
#root .register-login-page .content.register .verify-code-page.wrong .verify-code-inputs .vi__container .vi__character {
  box-shadow: 0px 5px 16px 0px rgba(208, 4, 22, 0.5098039216);
}
#root .register-login-page .content.register .verify-code-page.wrong .verify-code-inputs .vi__container .vi__character:where(.vi__character--selected) {
  color: #fb3748;
}
#root .register-login-page .content.register .verify-code-page.wrong .wrong-txt {
  display: block;
}
#root .register-login-page .content.register .fav-team-and-league form {
  display: flex;
  flex-direction: column;
  gap: 36px;
  margin-top: 20px;
}
#root .register-login-page .content.register .fav-team-and-league form .leagues .b_2 {
  margin-bottom: 10px;
}
#root .register-login-page .content.register .fav-team-and-league form .leagues .custom-dropdown .select-trigger .content-drop .img-drop {
  overflow: hidden;
  border-radius: 50%;
  aspect-ratio: 1/1;
}
#root .register-login-page .content.register .fav-team-and-league form .leagues .custom-dropdown .select-trigger .content-drop .img-drop img {
  -o-object-fit: cover;
     object-fit: cover;
}
#root .register-login-page .content.register .fav-team-and-league form .leagues .custom-dropdown .options .option .img-drop {
  overflow: hidden;
  border-radius: 50%;
  aspect-ratio: 1/1;
}
#root .register-login-page .content.register .fav-team-and-league form .leagues .custom-dropdown .options .option .img-drop img {
  -o-object-fit: cover;
     object-fit: cover;
}
#root .register-login-page .content.register .fav-team-and-league form hr {
  border: 0;
  height: 1px;
  color: rgba(0, 0, 0, 0.5019607843);
  background: rgba(0, 0, 0, 0.5019607843);
}
#root .register-login-page .content.register .fav-team-and-league form .teams .b_2 {
  margin-bottom: 10px;
}
#root .register-login-page .content.register .fav-team-and-league form .btns {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-top: 46px;
}

#root .register-login-page {
  display: flex;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
#root .register-login-page .img {
  display: flex;
  width: 40%;
}
#root .register-login-page .img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
#root .register-login-page .content {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 130px 160px 130px 90px;
  overflow: auto;
}
#root .register-login-page .content .form .third-party-register {
  display: flex;
  justify-content: space-between;
  gap: 22px;
  margin-top: 50px;
  margin-bottom: 25px;
}
#root .register-login-page .content .form .divider {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}
#root .register-login-page .content .form .divider hr {
  height: 1px;
  width: 100%;
  color: #d2d2d2;
  background-color: #d2d2d2;
}
#root .register-login-page .content .form .divider .b_2 {
  width: stretch;
  width: -moz-available;
  width: -webkit-fill-available;
  text-align: center;
}
#root .register-login-page .content .form .terms {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  height: 0;
  overflow: hidden;
  transition: 300ms all;
}
#root .register-login-page .content .form .terms .term {
  display: flex;
  align-items: center;
  gap: 5px;
}
#root .register-login-page .content .form .terms .term figure {
  width: 16px;
  height: 16px;
}
#root .register-login-page .content .form .terms .term figure svg {
  width: 100%;
  height: 100%;
}
#root .register-login-page .content .form .terms .term figure svg.xmark {
  fill: #d00416;
}
#root .register-login-page .content .form .terms .term figure svg.correct {
  fill: #1fc16b;
}
#root .register-login-page .content .form .terms .term .b_4.wrong, #root .register-login-page .content .form .terms .term .alertify .ajs-dialog .ajs-footer .wrong.ajs-ok, .alertify .ajs-dialog .ajs-footer #root .register-login-page .content .form .terms .term .wrong.ajs-ok {
  color: #d00416 !important;
}
#root .register-login-page .content .form .terms .term .b_4.correct, #root .register-login-page .content .form .terms .term .alertify .ajs-dialog .ajs-footer .correct.ajs-ok, .alertify .ajs-dialog .ajs-footer #root .register-login-page .content .form .terms .term .correct.ajs-ok {
  color: #1fc16b !important;
}
#root .register-login-page .content .form .terms.active {
  height: -moz-max-content;
  height: max-content;
}
#root .register-login-page .content .form .links {
  display: flex;
  gap: 24px;
  align-items: center;
  margin-bottom: 15px;
}
#root .register-login-page .content .form .links .b_3 {
  width: 100%;
  border: 3px solid #345dff;
  border-radius: 12px;
  color: #345dff;
  padding: 12px;
  background-color: #ffffff;
  text-align: center;
}
#root .register-login-page .content .form .links .b_3.active {
  background-color: #345dff;
  color: #ffffff;
}
#root .register-login-page .content .form .recaptcha {
  display: none;
  margin-bottom: 10px;
}
#root .register-login-page .content .form .recaptcha .b_3 {
  display: none;
  color: #d00416;
}
#root .register-login-page .content .form .recaptcha.wrong {
  border: 2px solid #d00416;
  border-radius: 5px;
  padding: 5px 8px;
}
#root .register-login-page .content .form .recaptcha.wrong .b_3 {
  display: inline-block;
}
#root .register-login-page .content .form .recaptcha.active {
  display: inline-block;
}/*# sourceMappingURL=main.css.map */