#root {
  .main_ads_or_img {
    margin: 95px 0 70px 0;
    height: 170px;
    border-radius: 12px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
