#root {
  .packages_page {
    margin-top: 75px;
    padding: 30px $main-gap 100px $main-gap;
    min-height: 100vh;
    .packages_hood {
      display: flex;
      flex-direction: column;
      gap: 25px;
      text-align: center;
      margin-bottom: 25px;
      .h_5 {
      }
      .h_8 {
      }
    }
    .packages_filter {
      margin-bottom: 40px;
      .filter {
        display: flex;
        align-items: stretch;
        gap: 15px;
        position: relative;
        .week,
        .status {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          gap: 5px;
        }
      }
      .order {
      }
    }
    .package_items {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 35px;
    }
  }
  .package_item {
    background-color: map-get($color, "neutral/100");
    border: 3px solid map-get($color, "dark/alpha/50");
    @include box_shadow(map-get($color, "dark/alpha/25"));
    border-radius: 16px;
    a {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px;
      .package_info {
        display: flex;
        align-items: center;
        gap: 70px;
        .b_1 {
          color: map-get($color, "dark");
          &.date {
          }
          &.id,
          &.correct_prediction {
            color: map-get($color, "primary/300");
          }
          &.point {
            color: map-get($color, "green/300");
            font-weight: map-get($font-weight, "m");
          }
        }
      }
      figure {
        width: 15px;
        svg {
          width: 100%;
          height: 100%;
          fill: map-get($color, "dark");
        }
      }
    }
    &.active {
      border-color: map-get($color, "green/300");
    }
  }
}
