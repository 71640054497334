#root {
  .custom-dropdown {
    position: relative;
    .disabled {
      display: none;
    }
    .select-trigger {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include box_shadow(map-get($color, "dark/alpha/25"));
      border-radius: 12px;
      padding: 5px 8px;
      background-color: map-get($color, "neutral/100");
      cursor: pointer;
      .content-drop {
        display: flex;
        align-items: center;
        width: 100%;
        gap: 25px;
        .img-drop {
          height: 70px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .txt {
          .h_8 {
          }
          .b_4 {
          }
        }
      }
      .arrow {
        padding: 0 32px;
        transform: rotate(0deg);
        transition: transform 0.3s;
        svg {
          width: 32px;
          height: 32px;
        }
        &.open {
          transform: rotate(-180deg);
        }
      }
      &.open {
        border-radius: 12px 12px 0 0;
      }
    }
    .options {
      display: none;
      position: absolute;
      width: 100%;
      background-color: map-get($color, "neutral/100");
      display: block;
      z-index: 2;
      @include box_shadow(map-get($color, "dark/alpha/25"));
      border-radius: 0 0 12px 12px;
      border-top: 0;
      min-height: 100px;
      max-height: 200px;
      overflow: auto;
      .option {
        display: flex;
        align-items: center;
        padding: 5px 8px;
        gap: 25px;
        border-bottom: 2px solid map-get($color, "dark/alpha/25");
        cursor: pointer;
        .img-drop {
          height: 70px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        &:nth-last-child(1) {
          border-bottom: 0;
        }
      }
    }
    &.disabled {
      .disabled {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: map-get($color, "neutral/alpha/10");
        border-radius: 12px;
        z-index: 1;
        cursor: no-drop;
      }
    }
  }
  .phone-dropdown {
    position: relative;
    .select-trigger {
      width: max-content;
      display: flex;
      align-items: center;
      border-radius: 8px 0 0 8px;
      padding: 15px 8px;
      background-color: map-get($color, "neutral/100");
      cursor: pointer;
      .content-drop {
        display: flex;
        align-items: center;
        gap: 12px;
        .img-drop {
          width: 28px;
          height: 20px;
          border-radius: 2px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .txt {
          .b_4 {
            font-weight: map-get($font-weight, "m");
          }
        }
      }
      .arrow {
        transform: rotate(0deg);
        transition: transform 0.3s;
        svg {
          width: 16px;
          height: 16px;
        }
        &.open {
          transform: rotate(-180deg);
        }
      }
    }
    .options {
      display: none;
      position: absolute;
      width: max-content;
      background-color: map-get($color, "neutral/100");
      display: block;
      z-index: 2;
      border-radius: 0 0 4px 4px;
      min-height: 60px;
      max-height: 120px;
      overflow: auto;
      @include box_shadow(map-get($color, "dark/alpha/25"));
      clip-path: inset(0px -60px -60px -60px);
      .option {
        display: flex;
        align-items: center;
        padding: 15px 8px;
        gap: 12px;
        justify-content: space-between;
        border-bottom: 2px solid map-get($color, "primary/alpha/10");
        cursor: pointer;
        .img-drop {
          width: 28px;
          height: 20px;
          border-radius: 2px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .txt {
          .b_4 {
            font-weight: map-get($font-weight, "m");
          }
        }
        &:nth-last-child(1) {
          border-bottom: 0;
        }
      }
    }
  }
  .multilang-dropdown {
    position: relative;
    .select-trigger {
      width: max-content;
      display: flex;
      align-items: center;
      // border-radius: 8px 0 0 8px;
      // padding: 15px 8px;
      background-color: map-get($color, "neutral/100");
      border: 2px solid map-get($color, "neutral/400");
      border-radius: 4px;
      padding: 2px 6px;
      cursor: pointer;
      .content-drop {
        display: flex;
        align-items: center;
        gap: 5px;
        .img-drop {
          width: 28px;
          height: 20px;
          border-radius: 2px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .txt {
          .b_1 {
            font-weight: map-get($font-weight, "m");
          }
        }
      }
    }
    // .options {
    //   display: none;
    //   position: absolute;
    //   width: max-content;
    //   background-color: map-get($color, "neutral/100");;
    //   display: block;
    //   z-index: 2;
    //   border-radius: 0 0 4px 4px;
    //   min-height: 60px;
    //   max-height: 120px;
    //   overflow: auto;
    //   @include box_shadow(map-get($color, "dark/alpha/25"));
    //   clip-path: inset(0px -60px -60px -60px);
    //   .option {
    //     display: flex;
    //     align-items: center;
    //     padding: 15px 8px;
    //     gap: 12px;
    //     justify-content: space-between;
    //     border-bottom: 2px solid map-get($color, "primary/alpha/10");
    //     cursor: pointer;
    //     .img-drop {
    //       width: 28px;
    //       height: 20px;
    //       border-radius: 2px;
    //       overflow: hidden;
    //       img {
    //         width: 100%;
    //         height: 100%;
    //       }
    //     }
    //     .txt {
    //       .b_4 {
    //         font-weight: map-get($font-weight, "m");
    //       }
    //     }
    //     &:nth-last-child(1) {
    //       border-bottom: 0;
    //     }
    //   }
    // }
  }
  .packages-dropdown {
    width: 15vw;
    position: relative;
    .select-trigger {
      display: flex;
      align-items: center;
      background-color: map-get($color, "neutral/100");
      // border: 2px solid map-get($color, "neutral/400");
      border-radius: 8px;
      padding: 10px 15px;
      @include box_shadow(map-get($color, "dark/alpha/25"));
      cursor: pointer;
      .content-drop {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        width: 100%;
        .txt {
          .b_3 {
            font-weight: map-get($font-weight, "m");
          }
        }
        .arrow {
          width: 8px;
          display: flex;
          align-items: center;
          svg {
            width: 100%;
            height: 100%;
          }
        }
      }
      &.open {
        border-radius: 8px 8px 0 0;
      }
    }
    .options {
      display: none;
      position: absolute;
      width: 100%;
      background-color: map-get($color, "neutral/100");
      display: block;
      z-index: 2;
      border-radius: 0 0 8px 8px;
      min-height: 60px;
      max-height: 120px;
      overflow: auto;
      @include box_shadow(map-get($color, "dark/alpha/25"));
      clip-path: inset(0px -60px -60px -60px);
      .option {
        display: flex;
        align-items: center;
        padding: 15px 8px;
        gap: 12px;
        justify-content: space-between;
        border-bottom: 2px solid map-get($color, "primary/alpha/10");
        cursor: pointer;
        .img-drop {
          width: 28px;
          height: 20px;
          border-radius: 2px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .txt {
          .b_3 {
            // font-weight: map-get($font-weight, "m");
          }
        }
        &:nth-last-child(1) {
          border-bottom: 0;
        }
      }
    }
  }
}
