#root {
  .bug_report_sect {
    border-top: 1px solid map-get($color, "footer");
    padding-top: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
    .bug_report_page {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 50vw;
      .h_6 {
        color: map-get($color, "footer");
      }
      form {
        .inputs {
          display: flex;
          flex-direction: column;
          gap: 30px;
          div {
            display: flex;
            flex-direction: column;
            gap: 15px;
            &.subject_image {
              flex-direction: row;
              div {
                width: 50%;
                .input {
                  background-color: map-get($color, "neutral/100");
                }
              }
            }
          }
          label {
            margin-bottom: -10px;
            span {
              color: map-get($color, "red/200");
              margin-left: 5px;
            }
          }
          input,
          textarea,
          select {
            padding: 10px;
          }
          textarea {
            resize: none;
            height: 150px;
          }
        }
        .background_btn {
          width: 100%;
          margin-top: 20px;
          padding: 10px;
          border-radius: 8px;
        }
      }
    }
  }
}
