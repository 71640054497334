#root {
  .register-login-page {
    display: flex;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    .img {
      display: flex;
      width: 40%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .content {
      width: 60%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 130px 160px 130px 90px;
      overflow: auto;
      .h_6 {
      }
      .form {
        .third-party-register {
          display: flex;
          justify-content: space-between;
          gap: 22px;
          margin-top: 50px;
          margin-bottom: 25px;
          .third_party_btns {
            svg {
            }
            .b_4 {
            }
          }
        }
        .divider {
          display: flex;
          align-items: center;
          margin-bottom: 25px;
          hr {
            height: 1px;
            width: 100%;
            color: map-get($color, "neutral/300");
            background-color: map-get($color, "neutral/300");
          }
          .b_2 {
            @include fill_available;
            text-align: center;
          }
        }
        .terms {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 5px;
          position: absolute;
          top: calc(100% + 10px);
          left: 0;
          height: 0;
          overflow: hidden;
          transition: 300ms all;
          .term {
            display: flex;
            align-items: center;
            gap: 5px;
            figure {
              width: 16px;
              height: 16px;
              svg {
                width: 100%;
                height: 100%;
                &.xmark {
                  fill: map-get($color, "red/200");
                }
                &.correct {
                  fill: map-get($color, "green/200");
                }
              }
            }
            .b_4 {
              &.wrong {
                color: map-get($color, "red/200") !important;
              }
              &.correct {
                color: map-get($color, "green/200") !important;
              }
            }
          }
          &.active {
            height: max-content;
          }
        }
        .links {
          display: flex;
          gap: 24px;
          align-items: center;
          margin-bottom: 15px;
          .b_3 {
            width: 100%;
            border: 3px solid map-get($color, "primary/300");
            border-radius: 12px;
            color: map-get($color, "primary/300");
            padding: 12px;
            // font-weight: map-get($font-weight, "m");
            background-color: map-get($color, "neutral/100");
            text-align: center;
            &.active {
              background-color: map-get($color, "primary/300");
              color: map-get($color, "neutral/100");
            }
          }
        }
        .recaptcha {
          display: none;
          margin-bottom: 10px;
          .b_3 {
            display: none;
            color: map-get($color, "red/200");
          }
          &.wrong {
            border: 2px solid map-get($color, "red/200");
            border-radius: 5px;
            padding: 5px 8px;
            .b_3 {
              display: inline-block;
            }
          }
          &.active {
            display: inline-block;
          }
        }
      }
    }
  }
}
