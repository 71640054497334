#root {
  .matches_page {
    margin-top: 75px;
    padding: 20px $main-gap 130px $main-gap;
    position: relative;
    min-height: 100vh;
    .matches_filter {
    }
    .matches_content {
      .h_5.hood {
        text-align: center;
        margin: 25px 0 70px 0;
      }
      .matches {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 48px;
        // .match {
        //   width: calc((100% / 2) - (130px / 2));
        //   @include box_shadow(map-get($color, "dark/alpha/25"));
        //   border-radius: 16px;
        //   background-color: map-get($color, "neutral/100");
        //   display: flex;
        //   flex-direction: column;
        //   align-items: center;
        //   justify-content: space-between;
        //   padding: 45px 12px;
        //   gap: 12px;
        //   .match_content {
        //     width: 100%;
        //     .h_5 {
        //       text-align: center;
        //       color: map-get($color, "dark/alpha/50");
        //     }
        //     .teams {
        //       .team_names {
        //         display: flex;
        //         align-items: center;
        //         justify-content: space-between;
        //         .team {
        //           flex: 1 1;
        //           color: map-get($color, "dark/alpha/50");
        //           &.left {
        //             text-align: left;
        //           }
        //           &.right {
        //             text-align: right;
        //           }
        //         }
        //         span.vs {
        //           flex: 1 1;
        //           display: flex;
        //           align-items: center;
        //           justify-content: center;
        //           color: map-get($color, "dark/alpha/50");
        //         }
        //       }
        //       .teams_last_matches {
        //         display: flex;
        //         align-items: center;
        //         justify-content: space-between;
        //         .team_last_matches {
        //           display: flex;
        //           flex-direction: column;
        //           gap: 5px;
        //           .b_4 {
        //             color: map-get($color, "dark/alpha/50");
        //           }
        //           .data {
        //             display: flex;
        //             align-items: center;
        //             gap: 8px;
        //             .match_info {
        //               display: inline-block;
        //               width: 10px;
        //               height: 10px;
        //               border-radius: 50%;
        //               border: 1px solid map-get($color, "dark/alpha/50");
        //               position: relative;
        //               .modal {
        //                 display: none;
        //                 position: absolute;
        //                 top: 12px;
        //                 z-index: 1;
        //                 padding: 8px;
        //                 color: map-get($color, "dark");
        //                 border: 1px solid map-get($color, "dark");
        //                 background: map-get($color, "neutral/100");
        //                 // border-radius: 4px 4px 4px 0;
        //                 width: max-content;
        //               }
        //               &.W {
        //                 background-color: map-get($color, "green/200");
        //                 border: 1px solid map-get($color, "green/200");
        //               }
        //               &.L {
        //                 background-color: map-get($color, "red/200");
        //                 border: 1px solid map-get($color, "red/200");
        //               }
        //               &.D {
        //                 background-color: map-get($color, "yellow/300");
        //                 border: 1px solid map-get($color, "yellow/300");
        //               }
        //               &:hover {
        //                 .modal {
        //                   display: inline-block;
        //                 }
        //               }
        //             }
        //           }
        //           &.left {
        //             text-align: left;
        //             align-items: flex-start;
        //             .modal {
        //               border-radius: 0px 4px 4px 4px;
        //               left: 3px;
        //               text-align: left;
        //             }
        //           }
        //           &.right {
        //             text-align: right;
        //             align-items: flex-end;
        //             .modal {
        //               right: 3px;
        //               border-radius: 4px 0px 4px 4px;
        //               text-align: right;
        //             }
        //           }
        //         }
        //       }
        //     }
        //   }
        //   .buttons {
        //     display: flex;
        //     justify-content: center;
        //     width: 100%;
        //     .background_btn,
        //     .outline_btn {
        //       border-radius: 1500px;
        //       padding: 12px 48px;
        //       .btn-loader {
        //       }
        //       .b_3 {
        //         font-weight: map-get($font-weight, "b");
        //       }
        //     }
        //   }
        //   &.added {
        //     @include box_shadow(map-get($color, "green/alpha/50"));
        //     .buttons {
        //       justify-content: space-between;
        //     }
        //   }
        // }
      }
      &.no {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 50px;
        margin-bottom: 50px;
        color: map-get($color, "dark/alpha/50");
        .h_3 {
          text-align: center;
          width: 75%;
        }
        .h_6 {
          text-align: center;
        }
        figure {
          width: 28px;
          svg {
            width: 100%;
            height: 100%;
            fill: map-get($color, "dark/alpha/50");
          }
        }
      }
    }
  }
  .favorite_match {
    margin-top: 60px;
    .hood_match {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 40px;
      .h_5 {
      }
      a.outline_btn {
        padding: 2px 10px;
        border-radius: 16px;
        cursor: pointer;
        .b_1 {
          font-weight: map-get($font-weight, "m");
        }
      }
    }
    .matches {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      gap: 48px;
      position: relative;
    }
  }
}
