$color: (
  "primary/100": #b7dbf8,
  "primary/200": #6fb8f0,
  "primary/300": #345dff,
  "primary/400": #224eff,
  "primary/500": #0c3dff,
  "primary/alpha/50": #345dff80,
  "primary/alpha/10": #2794e91a,
  "secondary/100": #7581f0,
  "secondary/200": #3042e9,
  "secondary/300": #1423b9,
  "secondary/400": #0c1674,
  "secondary/500": #05092f,
  "secondary/alpha/10": #05092f1a,
  "footer/color": #1a4573,
  "neutral/100": #ffffff,
  "neutral/200": #e8e8e8,
  "neutral/300": #d2d2d2,
  "neutral/400": #bbbbbb,
  "neutral/500": #a4a4a4,
  "neutral/600": #8e8e8e,
  "neutral/700": #777777,
  "neutral/800": #5f5f5f,
  "neutral/900": #4a4a4a,
  "neutral/1000": #333333,
  "neutral/alpha/40": #33333366,
  "red/100": #fb3748,
  "red/200": #d00416,
  "red/alpha/50": #d0041682,
  "red/alpha/25": #d0041640,
  "yellow/100": #fff5cd,
  "yellow/200": #ffdb43,
  "yellow/300": #dfb400,
  "yellow/400": #877a42,
  "yellow/alpha/10": #ffdb431a,
  "green/100": #dfffee,
  "green/200": #1fc16b,
  "green/300": #1f9960,
  "green/alpha/50": #1fc16b82,
  "green/alpha/25": #1fc16b40,
  "blue/100": #dfe5fe,
  "blue/300": #79819f,
  "footer": #1a4573,
  "footer/alpha/50": #1a457380,
  "footer/alpha/25": #1a457340,
  "dark": #000000,
  "dark/alpha/50": #00000080,
  "dark/alpha/25": #00000040,
  "dark/alpha/10": #0000001a,
  "gold": #908b79,
  "silver": #8a8a8a,
  "bronze": #9b7255,
);
$font: "Murecho", sans-serif;
$font-weight: (
  "r": 400,
  "m": 500,
  "b": 600,
  "bb": 700,
);
$main-gap: 12vw;
$transition: 300ms;
// white:#f6f8ff;
// black: #02030c;
