#root {
  footer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    background: map-get($color, "footer");
    padding: 55px 80px 67px 80px;
    gap: 50px;
    .top {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      width: 100%;
      .logo {
        flex: 1 1 calc(100% / 3);
        margin-bottom: -20px;
        .link {
          display: flex;
          width: fit-content;
          figure {
            width: 140px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      .mid {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 82px;
        flex: 1 1 calc(100% / 3);
        .h_5 {
          color: map-get($color, "neutral/100");
          width: 650px;
          text-align: center;
        }
        .background_btn {
          width: 460px;
          background: map-get($color, "neutral/100");
          border-color: map-get($color, "neutral/100");
          color: map-get($color, "dark");
        }
        .pages {
          display: flex;
          align-items: center;
          gap: 40px;
          .h_8 {
            color: map-get($color, "neutral/100");
          }
        }
      }
      .social-medias {
        flex: 1 1 calc(100% / 3);
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 35px;
        figure {
          height: 19px;
          svg {
            width: 100%;
            height: 100%;
            fill: map-get($color, "neutral/100");
          }
        }
      }
    }
    .conditions {
      display: flex;
      gap: 30px;
      .b_3 {
        color: map-get($color, "neutral/100");
      }
    }
  }
  footer.footer-mini {
    padding: 38px $main-gap;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    figure {
      width: 140px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .links {
      display: flex;
      justify-content: space-between;
      // flex: 1 1;
      gap: 10vw;
      div {
        display: flex;
        flex-direction: column;
        gap: 16px;
        .b_1 {
          color: map-get($color, "neutral/100");
        }
      }
    }
    .social-icons {
      display: flex;
      flex-direction: column;
      gap: 32px;
      figure {
        display: flex;
        svg {
          fill: map-get($color, "neutral/100");
          width: 100%;
          height: 100%;
        }
        &.twitter {
          width: 16px;
        }
        &.facebook {
          width: 10px;
        }
        &.instagram {
          width: 19px;
        }
      }
    }
    .markets {
      display: flex;
      flex-direction: column;
      gap: 12px;
      figure {
        width: auto;
        height: 55px;
        display: flex;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
