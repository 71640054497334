#root {
  .leaderboard_page {
    margin-top: 75px;
    padding: 100px $main-gap;
    h5.h_5.hood {
      text-align: center;
      margin-bottom: 30px;
    }
    .info_leaderboard {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      width: 100%;
      gap: 40px;
      .total {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 10px;
        flex: 1 1 calc((100% - (40px * 2)) / 5);
        border-radius: 16px;
        padding: 16px;
        @include box_shadow(map-get($color, "dark/alpha/25"));
        figure {
          height: 60px;
          background-color: red;
        }
        .content_total {
          // display: flex;
          p.b_1 {
            margin-bottom: 15px;
          }
          h6.h_7 {
          }
        }
        &.total_player {
          background-color: map-get($color, "green/100");
          color: map-get($color, "green/300");
        }
        &.total_points {
          background-color: map-get($color, "blue/100");
          color: map-get($color, "blue/300");
        }
      }
      .remaining_time {
        @extend .total;
        background-color: map-get($color, "yellow/100");
        flex: 1 1 calc(((100% - (40px * 2)) / 5) * 3);
        .remaining_time_content {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 40px;
          h6.h_6 {
            text-transform: uppercase;
            color: map-get($color, "yellow/400");
          }
          p.h_6 {
            display: flex;
            align-items: stretch;
            gap: 8px;
            color: map-get($color, "dark/alpha/50");
            .day,
            .hours {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-between;
              span {
                &.h_8 {
                  color: map-get($color, "neutral/400");
                }
              }
            }
          }
        }
        p.b_1 {
          border-top: 1px solid map-get($color, "dark/alpha/25");
          padding-top: 10px;
          color: map-get($color, "yellow/400");
        }
      }
    }
    .leaderboard_sect {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 50px;
      margin-top: 50px;
      .h_7.hood {
        text-align: center;
        span {
          margin-right: 10px;
        }
      }
      .filter {
        width: 100%;
      }
      .leaderboard {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        .user_queue {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 15px;
          span.dot {
            width: 15px;
            height: 15px;
            background-color: map-get($color, "neutral/600");
            border-radius: 50%;
          }
        }
      }
      .outline_btn {
        margin-top: 50px;
        width: max-content;
        border-radius: 1500px;
        padding: 2px 10px;
      }
    }
  }
  .leaderboard-dropdown {
    width: 20vw;
    margin-bottom: 20px;
    .select-trigger {
      padding: 10px;
      .content-drop {
        gap: 10px;
        justify-content: space-between;
        .b_1 {
          font-weight: map-get($font-weight, "m");
          color: map-get($color, "neutral/900");
        }
      }
      .arrow {
        padding: 0;
        svg {
          width: 16px;
          height: 16px;
          fill: map-get($color, "neutral/900");
        }
      }
    }
    .options {
      .b_1 {
        color: map-get($color, "neutral/900");
      }
    }
  }
  .leaderboard_card {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: map-get($color, "neutral/100");
    @include box_shadow(map-get($color, "dark/alpha/25"));
    border-radius: 16px;
    padding: 12px;
    // overflow: hidden;
    .left {
      display: flex;
      align-items: center;
      gap: 15px;
      .queue {
        display: flex;
        align-items: center;
        p.b_1 {
          font-weight: map-get($font-weight, "b");
          white-space: pre;
          font-family: monospace;
        }
      }
      figure {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        overflow: hidden;
        @include box_shadow(map-get($color, "dark/alpha/10"));
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      p.name {
      }
    }
    .right {
      display: flex;
      align-items: center;
      gap: 15px;
      .point {
        color: map-get($color, "primary/300");
        span {
          margin-right: 5px;
        }
      }
      .report {
        position: relative;
        figure {
          height: 20px;
          width: 20px;
          opacity: 0;
          transition: $transition;
          cursor: pointer;
          svg {
            width: 100%;
            height: 100%;
            fill: map-get($color, "dark/alpha/25");
          }
        }
        .content {
          position: absolute;
          background-color: map-get($color, "neutral/100");
          @include box_shadow(map-get($color, "dark/alpha/25"));
          top: 0;
          right: 0;
          width: max-content;
          display: flex;
          flex-direction: column;
          gap: 5px;
          padding: 15px 10px;
          border-radius: 5px;
          display: none;
          .b_1 {
            color: map-get($color, "neutral/800");
            transition: $transition;
            &:hover {
              color: map-get($color, "dark");
            }
          }
        }
        &:hover {
          .content {
            display: flex;
          }
        }
      }
    }
    &.my {
      @include box_shadow(map-get($color, "primary/300"));
    }
    &:hover {
      .report {
        figure {
          opacity: 1;
        }
      }
    }
  }
  .leaderboard_king_card {
    @extend .leaderboard_card;
    width: calc((100% - (20px * 2)) / 3);
    align-items: flex-start;
    .leaderboard_king_card_content {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      gap: 15px;
      .left {
        figure.queue,
        figure.profile {
          width: 60px;
          height: 60px;
        }
        figure.queue {
          box-shadow: 0 0 0 0;
          img {
          }
        }
        figure.profile {
          // @include box_shadow(map-get($color, "dark/alpha/10"));
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
      .right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0;
        .h_8 {
          span {
            margin-right: 5px;
          }
        }
      }
    }
    .report {
      display: flex;
      justify-content: flex-end;
      // width: 100%;
      position: relative;
      figure {
        height: 20px;
        fill: map-get($color, "dark/alpha/25");
        opacity: 0;
        transition: $transition;
        cursor: pointer;
        svg {
          width: 100%;
          height: 100%;
        }
      }
      .content {
        position: absolute;
        background-color: map-get($color, "neutral/100");
        @include box_shadow(map-get($color, "dark/alpha/25"));
        top: 0;
        right: 0;
        width: max-content;
        display: flex;
        flex-direction: column;
        gap: 5px;
        padding: 15px 10px;
        border-radius: 5px;
        display: none;
        .b_1 {
          color: map-get($color, "neutral/800");
          transition: $transition;
          &:hover {
            color: map-get($color, "dark");
          }
        }
      }
      &:hover {
        .content {
          display: flex;
        }
      }
    }
    &.first {
      color: map-get($color, "gold");
    }
    &.second {
      color: map-get($color, "silver");
    }
    &.thirth {
      color: map-get($color, "bronze");
    }
    &:hover {
      .report {
        figure {
          opacity: 1;
        }
      }
    }
  }
}
