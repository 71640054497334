.mini-loader {
  background-color: map-get($color, "neutral/100");
  .loader {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 50px;
    height: 50px;
    font-size: 0;
    display: inline-block;
    margin: -25px 0 0 -25px;
    text-indent: -9999em;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    div {
      background-color: map-get($color, "footer");
      display: inline-block;
      float: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 50px;
      height: 50px;
      opacity: 0.5;
      border-radius: 50%;
      -webkit-animation: mini-loader 2s ease-in-out infinite;
      animation: mini-loader 2s ease-in-out infinite;
      &:last-child {
        -webkit-animation-delay: -1s;
        animation-delay: -1s;
      }
    }
  }
}

.btn-loader {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: inline-block;
  border-top: 3px solid map-get($color, "neutral/100");
  border-right: 3px solid transparent;
  box-sizing: border-box;
  animation: btn-loader 1s linear infinite;
  &.m {
    width: 20px;
    height: 20px;
  }
  &.s {
    width: 15px;
    height: 15px;
    border-top: 2px solid map-get($color, "neutral/100");
    border-right: 2px solid transparent;
  }
}

@keyframes mini-loader {
  0%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes btn-loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
