.match_component {
  // width: calc((100% / 2) - (130px / 2));
  width: calc((100% / 2) - (48px / 2));
  // width: calc((100% / 2) - (36px / 2));
  @include box_shadow(map-get($color, "dark/alpha/25"));
  border-radius: 16px;
  background-color: map-get($color, "neutral/100");
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 45px 12px;
  gap: 12px;
  position: relative;
  .background_btn.btn--delete_to_basket {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    border-radius: 50%;
    padding: 5px 8px;
    @include box_shadow(map-get($color, "green/alpha/50"));
    background-color: map-get($color, "neutral/100");
    border: none;
    .btn-loader {
      border-top: 2px solid map-get($color, "red/200");
    }
    figure {
      width: 14px;
      svg {
        width: 100%;
        height: 100%;
        fill: map-get($color, "red/200");
      }
    }
  }
  .existing_info {
    position: absolute;
    top: 15px;
    left: 15px;
    cursor: pointer;
    figure {
      display: flex;
      width: 16px;
      height: 16px;
      padding: 2px;
      border-radius: 50%;
      border: 1px solid map-get($color, "green/300");
      animation: opacity 1s linear infinite;
      svg {
        width: 100%;
        height: 100%;
        fill: map-get($color, "green/300");
      }
    }
    .modal {
      position: absolute;
      bottom: 16px;
      left: 16px;
      padding: 14px 8px;
      border: 1px solid map-get($color, "green/300");
      background: map-get($color, "neutral/100");
      border-radius: 4px 4px 4px 0;
      width: max-content;
      color: map-get($color, "green/300");
      display: none;
    }
    &:hover {
      figure {
        animation: none;
      }
      .modal {
        display: inline-block;
      }
    }
  }
  .match_content {
    width: 100%;
    h5.h_8 {
      text-align: center;
      color: map-get($color, "dark/alpha/50");
    }
    .teams {
      .team_names {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .team {
          flex: 1 1;
          color: map-get($color, "dark/alpha/50");
          &.left {
            text-align: left;
          }
          &.right {
            text-align: right;
          }
        }
        span.vs {
          flex: 1 1;
          display: flex;
          align-items: center;
          justify-content: center;
          color: map-get($color, "dark/alpha/50");
        }
      }
      .teams_last_matches {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .team_last_matches {
          display: flex;
          flex-direction: column;
          gap: 5px;
          .b_4 {
            color: map-get($color, "dark/alpha/50");
          }
          .data {
            display: flex;
            align-items: center;
            gap: 8px;
            .match_info {
              display: inline-block;
              width: 10px;
              height: 10px;
              border-radius: 50%;
              border: 1px solid map-get($color, "dark/alpha/50");
              position: relative;
              .modal {
                display: none;
                position: absolute;
                top: 12px;
                z-index: 1;
                padding: 8px;
                color: map-get($color, "dark");
                border: 1px solid map-get($color, "dark");
                background: map-get($color, "neutral/100");
                width: max-content;
              }
              &.W {
                background-color: map-get($color, "green/200");
                border: 1px solid map-get($color, "green/200");
              }
              &.L {
                background-color: map-get($color, "red/200");
                border: 1px solid map-get($color, "red/200");
              }
              &.D {
                background-color: map-get($color, "yellow/300");
                border: 1px solid map-get($color, "yellow/300");
              }
              &:hover {
                .modal {
                  display: inline-block;
                }
              }
            }
          }
          &.left {
            text-align: left;
            align-items: flex-start;
            .modal {
              border-radius: 0px 4px 4px 4px;
              left: 3px;
              text-align: left;
            }
          }
          &.right {
            text-align: right;
            align-items: flex-end;
            .modal {
              right: 3px;
              border-radius: 4px 0px 4px 4px;
              text-align: right;
            }
          }
        }
      }
    }
    .statistics {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      border: 3px solid map-get($color, "neutral/alpha/40");
      padding: 10px;
      border-radius: 24px;
      margin-top: 25px;
      .b_2 {
        font-weight: map-get($font-weight, "m");
      }
      .statistic {
        display: flex;
        justify-content: space-evenly;
        width: 100%;
        .b_3 {
          color: map-get($color, "neutral/1000");
          font-weight: map-get($font-weight, "b");
          display: flex;
          flex-direction: column;
          align-items: center;
          span {
          }
        }
      }
    }
  }
  .background_btn {
    border-radius: 1500px;
    padding: 12px 48px;
    .btn-loader {
    }
    .b_3 {
      font-weight: map-get($font-weight, "b");
    }
    &:disabled,
    &[disabled] {
      background: none;
      border-color: map-get($color, "primary/300");
      color: map-get($color, "primary/300");
    }
  }
  .selected_and_delete {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100%;
    .selected_and_point {
      // display: flex;
      // justify-content: center;
      // flex: 1 1 40%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .prediction_selected {
        flex: 1 1;
        .h_8 {
          display: flex;
          align-items: center;
          flex-direction: column;
          // gap: 10px;
          span {
            color: map-get($color, "primary/300");
          }
        }
      }
      .prediction_point {
        flex: 1 1;
        .h_8 {
          display: flex;
          align-items: center;
          flex-direction: column;
          // gap: 10px;
          span {
            color: map-get($color, "primary/300");
          }
        }
      }
    }
    .delete {
      // flex: 1 1 10%;
      .background_btn {
        padding: 6px 36px;
        .b_4 {
        }
      }
    }
  }
  &.added {
    @include box_shadow(map-get($color, "green/alpha/50"));
    .buttons {
      justify-content: space-between;
    }
  }
}

@keyframes opacity {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
}
