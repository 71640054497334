* {
  @include reset;
}

body {
  font-family: $font;
  overflow-x: hidden;
  background-image: url("/public/img/background.svg"),
    url("/public/img/background.svg");
  background-size: 500px 500px;
  background-position: 0 0, 250px 250px;
}

// #root {
//   padding-top: 75px;
// }
a,
button {
  border: none;
  cursor: pointer;
}

iframe#webpack-dev-server-client-overlay {
  display: none;
  opacity: 0 !important;
}
