#root {
  .homepage {
    margin-top: 75px;
    padding: 20px $main-gap 130px $main-gap;
    min-height: 100vh;
    .homepage_buttons {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      gap: 36px;
      margin: 50px 0;
      a {
        display: flex;
        align-items: center;
        gap: 30px;
        background-color: map-get($color, "neutral/100");
        @include box_shadow(map-get($color, "dark/alpha/25"));
        border-radius: 16px;
        padding: 12px;
        width: calc(100%);
        figure {
          height: 60px;
          svg {
            width: 100%;
            height: 100%;
          }
          img {
            width: 100%;
            height: 100%;
          }
        }
        p.h_8 {
          color: map-get($color, "dark");
        }
      }
    }
    .premium_discover {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 12px;
      // width: calc(((100% / 4) * 3) - (48px / 3));
      width: calc(100% / 2 - (48px / 2));
      padding: 16px;
      border-radius: 24px;
      background-color: map-get($color, "neutral/100");
      @include box_shadow(map-get($color, "dark/alpha/25"));
      margin-top: 65px;
      figure {
        width: 100%;
        height: 120px;
        border-radius: 12px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .premium_discover_content {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 12px;
        .h_7 {
          // display: flex;
          span {
            margin-right: 8px;
          }
        }
        .b_1 {
          span {
            margin-left: 8px;
          }
        }
      }
      .background_btn.h_8 {
        border-radius: 1500px;
        padding: 12px 36px;
      }
    }
  }
  .homepage-2 {
    .first {
      width: 100%;
      height: 100vh;
      overflow: hidden;
      position: relative;
      .img {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        .filter {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: map-get($color, "neutral/alpha/40");
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .txt {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        color: map-get($color, "neutral/100");
        .h_7 {
        }
        .h_8 {
        }
        .background_btn {
          width: 20vw;
          margin-top: 50px;
        }
      }
    }
    .rewards {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: map-get($color, "footer");
      padding: 150px 90px;
      gap: 80px;
      .content {
        flex: 1 1 100%;
        .h_7 {
          color: map-get($color, "neutral/100");
          // width: 80%;
        }
        .background_btn {
          background-color: map-get($color, "neutral/100");
          color: map-get($color, "footer");
          width: fit-content;
          margin-top: 30px;
        }
      }
      figure {
        flex: 1 1 100%;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .memberships {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 30px;
      padding: 150px 90px;
      .background_btn {
        background: map-get($color, "footer");
      }
      .membership {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 25px;
        border: 3px solid map-get($color, "footer");
        border-radius: 16px;
        padding: 24px;
        flex: 1 1 calc((100% / 3) - 30px);
        .hood {
          display: flex;
          align-items: flex-end;
          justify-content: center;
          gap: 5px;
          .b_1 {
            color: map-get($color, "footer");
            font-weight: map-get($font-weight, "m");
          }
          p.b_1 {
            color: map-get($color, "dark");
            font-weight: map-get($font-weight, "m");
          }
        }
      }
      .free-plan {
        display: flex;
        gap: 30px;
        .membership {
          flex: 1 1 calc(((100% / 3) * 2) - 30px);
        }
        .how-to-play {
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: space-between;
          background-color: map-get($color, "footer");
          padding: 24px;
          border: 3px solid map-get($color, "footer");
          border-radius: 12px;
          flex: 1 1 calc((100% / 3) - 30px);
          .b_1 {
            width: 80%;
            text-align: center;
            color: map-get($color, "neutral/100");
            font-weight: map-get($font-weight, "m");
          }
          .background_btn {
            width: 100%;
            background-color: map-get($color, "neutral/100");
            color: map-get($color, "dark");
            font-weight: map-get($font-weight, "r");
          }
        }
      }
    }
    .how-to {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 150px 90px;
      .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 50px;
        flex: 1 1 100%;
        figure {
          img {
          }
        }
        .h_8 {
          text-align: center;
        }
      }
    }
  }
  .coming-soon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: #113357;
    gap: 30px;
    .h_1 {
      color: map-get($color, "neutral/100");
    }
    .h_5 {
      text-align: center;
      width: 35%;
      color: map-get($color, "neutral/100");
    }
    .outline_btn {
      color: map-get($color, "neutral/100");
      background: transparent;
      border-color: map-get($color, "neutral/100");
    }
  }
}
