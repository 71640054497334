#root {
  .package_detail {
    margin-top: 75px;
    padding: 30px $main-gap 100px $main-gap;
    min-height: 100vh;
    .hood {
      display: flex;
      flex-direction: column;
      gap: 25px;
      text-align: center;
      margin-bottom: 25px;
      .h_5 {
      }
    }
    .matches {
      display: flex;
      flex-wrap: wrap;
      gap: 36px;
      width: 100%;
      margin-bottom: 60px;
    }
  }
}
