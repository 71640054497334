#root {
  .modal-message {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: map-get($color, "dark/alpha/25");
    z-index: 99;
  }
  .modal_prediction {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 9;
    background-color: map-get($color, "dark/alpha/25");
    display: flex;
    align-items: center;
    justify-content: center;
    .match {
      width: 40vw;
      @include box_shadow(map-get($color, "dark/alpha/25"));
      border-radius: 12px;
      background-color: map-get($color, "neutral/100");
      overflow: hidden;
      .match_scroll {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 24px;
        gap: 12px;
        overflow: auto;
        max-height: 90vh;
        .modal_close {
          position: absolute;
          top: 0;
          right: 0;
          display: flex;
          border-radius: 50%;
          padding: 5px 8px;
          @include box_shadow(map-get($color, "dark/alpha/25"));
          background-color: map-get($color, "neutral/100");
          cursor: pointer;
          figure {
            display: flex;
            width: 14px;
            svg {
              width: 100%;
              height: 100%;
              fill: map-get($color, "dark/alpha/50");
            }
          }
        }
        .match_content {
          width: 100%;
          h5.h_8 {
            text-align: center;
            color: map-get($color, "dark/alpha/50");
          }
          .teams {
            .team_names {
              display: flex;
              align-items: center;
              justify-content: space-between;
              .team {
                flex: 1 1;
                color: map-get($color, "dark/alpha/50");
                &.left {
                  text-align: left;
                }
                &.right {
                  text-align: right;
                }
              }
              span.vs {
                flex: 1 1;
                display: flex;
                align-items: center;
                justify-content: center;
                color: map-get($color, "dark/alpha/50");
              }
            }
            .teams_last_matches {
              display: flex;
              align-items: center;
              justify-content: space-between;
              .team_last_matches {
                display: flex;
                flex-direction: column;
                gap: 5px;
                .b_4 {
                  color: map-get($color, "dark/alpha/50");
                }
                .data {
                  display: flex;
                  align-items: center;
                  gap: 8px;
                  .match_info {
                    display: inline-block;
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    border: 1px solid map-get($color, "dark/alpha/50");
                    position: relative;
                    .modal {
                      display: none;
                      position: absolute;
                      top: 12px;
                      z-index: 1;
                      padding: 8px;
                      color: map-get($color, "dark");
                      border: 1px solid map-get($color, "dark");
                      background: map-get($color, "neutral/100");
                      // border-radius: 4px 4px 4px 0;
                      width: max-content;
                    }
                    &.W {
                      background-color: map-get($color, "green/200");
                      border: 1px solid map-get($color, "green/200");
                    }
                    &.L {
                      background-color: map-get($color, "red/200");
                      border: 1px solid map-get($color, "red/200");
                    }
                    &.D {
                      background-color: map-get($color, "yellow/300");
                      border: 1px solid map-get($color, "yellow/300");
                    }
                    &:hover {
                      .modal {
                        display: inline-block;
                      }
                    }
                  }
                }
                &.left {
                  text-align: left;
                  align-items: flex-start;
                  .modal {
                    border-radius: 0px 4px 4px 4px;
                    left: 3px;
                    text-align: left;
                  }
                }
                &.right {
                  text-align: right;
                  align-items: flex-end;
                  .modal {
                    right: 3px;
                    border-radius: 4px 0px 4px 4px;
                    text-align: right;
                  }
                  .data {
                    flex-direction: row-reverse;
                  }
                }
              }
            }
          }
        }
        .divider {
          display: flex;
          align-items: center;
          width: 100%;
          gap: 15px;
          hr {
            width: 100%;
            height: 1px;
            color: map-get($color, "dark/alpha/25");
            background-color: map-get($color, "dark/alpha/25");
          }
          figure {
            width: 50px;
            display: flex;
            svg {
              width: 100%;
              height: 100%;
              fill: map-get($color, "dark/alpha/50");
            }
          }
        }
        .predictions_and_scores {
          display: flex;
          flex-direction: column;
          width: 100%;
          gap: 12px;
          .option {
            border-radius: 1500px;
            cursor: pointer;
            &.active {
              background-color: map-get($color, "primary/300");
              color: map-get($color, "neutral/100");
            }
          }
          .predictions {
            display: flex;
            justify-content: space-between;
            align-items: stretch;
            gap: 16px;
            .option {
              width: 100%;
              font-weight: map-get($font-weight, "b");
              padding: 12px 12px;
            }
          }
          .scores {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 16px;
            .option {
              width: calc((100% / 5) - 16px);
              padding: 10px;
            }
            &:has(.option:last-child:nth-child(5n)) {
              justify-content: space-between;
            }
          }
        }
        .background_btn {
          border-radius: 1500px;
          padding: 12px 48px;
          margin-top: 24px;
          .b_3 {
            font-weight: map-get($font-weight, "b");
          }
        }
      }
    }
  }
  .modal_report_user {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 9;
    background-color: map-get($color, "dark/alpha/25");
    display: flex;
    align-items: center;
    justify-content: center;
    .popup_report_user {
      width: 60vw;
      @include box_shadow(map-get($color, "dark/alpha/25"));
      border-radius: 24px;
      background-color: map-get($color, "neutral/100");
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 24px;
      gap: 12px;
      max-height: 100vh;
      overflow: auto;
      .top {
        .user_details {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 30px;
          figure {
            width: 70px;
            height: 70px;
            border-radius: 50%;
            overflow: hidden;
            border: 1px solid map-get($color, "dark/alpha/25");
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          p.h_8 {
            // text-align: center;
          }
        }
        .report {
          h6.h_6 {
            text-align: center;
            margin: 20px 0;
          }
          .report_type {
            display: flex;
            flex-direction: column;
            gap: 10px;
            div {
              input {
              }
              label {
                margin-left: 5px;
                span {
                  color: map-get($color, "red/200");
                  margin-left: 5px;
                }
              }
            }
          }
          .desc {
            display: flex;
            flex-direction: column;
            gap: 5px;
            margin: 10px 0 30px 0;
            label {
              span {
                color: map-get($color, "red/200");
                margin-left: 5px;
              }
            }
            textarea {
              resize: none;
              height: 150px;
            }
          }
        }
      }
      .buttons {
        display: flex;
        justify-content: space-evenly;
        align-items: stretch;
        width: 100%;
        .outline_btn,
        .background_btn {
          padding: 7px 25px;
          border-radius: 1500px;
        }
        .background_btn {
          background-color: map-get($color, "red/alpha/50");
          border: none;
        }
      }
    }
  }
}
