#root {
  .leagues_page {
    margin-top: 75px;
    padding: 100px $main-gap;
    .hood_league {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 40px;
      .h_5 {
      }
    }
    .leagues {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      gap: 48px;
      position: relative;
    }
  }
  .leagues_component {
    .hood_league {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 40px;
      .h_5 {
      }
      a.outline_btn {
        padding: 2px 10px;
        border-radius: 16px;
        cursor: pointer;
        .b_1 {
          font-weight: map-get($font-weight, "m");
        }
      }
    }
    .leagues {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      gap: 48px;
      position: relative;
    }
  }
  .league_component {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 1 calc((100% / 4) - 48px);
    @include box_shadow(map-get($color, "dark/alpha/25"));
    padding: 24px;
    border-radius: 24px;
    background-color: map-get($color, "neutral/100");
    gap: 8px;
    position: relative;
    overflow: hidden;
    .block {
      display: none;
      position: absolute;
      // width: 100%;
      // height: 100%;
      // background-color: map-get($color, "dark/alpha/50");
      top: 20px;
      left: 20px;
      // cursor: not-allowed;
      figure {
        width: 24px;
        // margin: 20px;
        svg {
          width: 100%;
          height: 100%;
          fill: map-get($color, "yellow/200");
          // text-shadow: 0px 5px 16px 0px red;
          // @include box_shadow(red);
          stroke-width: 5%;
          stroke: map-get($color, "dark/alpha/25");
        }
      }
    }
    .content_league {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      figure {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        overflow: hidden;
        border: 1px solid map-get($color, "dark/alpha/25");
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .h_8 {
        text-align: center;
      }
      .b_2 {
        text-align: center;
      }
    }
    .bottom {
      .b_2 {
        text-align: center;
        color: map-get($color, "green/300");
        font-weight: map-get($font-weight, "m");
        margin-bottom: 10px;
      }
      .background_btn {
        width: 100%;
        padding: 8px;
        border-radius: 150px;
      }
    }
    &.premium {
      .block {
        display: inline-block;
      }
    }
  }
}
