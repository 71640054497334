#root {
  .achievements_component {
    margin-top: 50px;
    .hood {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin-bottom: 40px;
      h5.h_5 {
      }
      .outline_btn.b_1 {
        padding: 2px 10px;
        border-radius: 16px;
        // cursor: pointer;
        font-weight: map-get($font-weight, "m");
      }
    }
    .achievements {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      gap: 36px;
      width: 100%;
    }
  }
  .achievement_card {
    display: flex;
    align-items: center;
    gap: 12px;
    background-color: map-get($color, "neutral/100");
    @include box_shadow(map-get($color, "dark/alpha/25"));
    border-radius: 16px;
    padding: 10px;
    width: calc((100% - (36px * 2)) / 3);
    position: relative;
    overflow: hidden;
    figure {
      width: 60px;
      display: flex;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .block {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: map-get($color, "dark/alpha/25");
      cursor: not-allowed;
    }
    .card_content {
      .h_8 {
      }
      .b_4 {
      }
    }
  }
}
