#root {
  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px $main-gap;
    border-radius: 0 0 16px 16px;
    @include box_shadow(map-get($color, "dark/alpha/25"));
    background-color: map-get($color, "neutral/100");
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 5;
    .logo {
      figure {
        display: flex;
        width: 140px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .nav-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 24px;
      .pages {
        display: flex;
        align-items: center;
        gap: 24px;
        .b_1 {
          color: map-get($color, "dark");
        }
        .prediction_link {
          position: relative;
          margin-right: 10px;
          span {
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: -5px;
            right: -20px;
            background: map-get($color, "primary/300");
            // padding: 0px 3px;
            border-radius: 50%;
            color: map-get($color, "neutral/100");
          }
        }
      }
      .nav-btns {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 12px;
        .b_2 {
          padding: 8px 12px;
          border-radius: 150px;
        }
        .background_btn {
        }
        .outline_btn {
        }
      }
      .select-langs {
      }
      .user {
        display: flex;
        align-items: center;
        gap: 24px;
        .notifications {
          figure {
            height: 25px;
            svg {
              width: 100%;
              height: 100%;
              fill: map-get($color, "dark");
            }
          }
        }
        .profile {
          position: relative;
          display: flex;
          align-items: center;
          gap: 25px;
          figure {
            width: 60px;
            height: 60px;
            overflow: hidden;
            border-radius: 50%;
            @include box_shadow(map-get($color, "dark/alpha/25"));
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .point {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 2px;
            .b_2 {
              font-weight: map-get($font-weight, "b");
              color: map-get($color, "primary/300");
              filter: drop-shadow(0.5px 0 0 map-get($color, "primary/alpha/10"))
                drop-shadow(0 0.5px 0 map-get($color, "primary/alpha/10"))
                drop-shadow(-0.5px 0 0 map-get($color, "primary/alpha/10"))
                drop-shadow(0 -0.5px 0 map-get($color, "primary/alpha/10"));
            }
          }
        }
      }
    }
  }
}
