@import url("https://fonts.googleapis.com/css2?family=Murecho:wght@100..900&display=swap");
// @import url("/vendors/font/murecho.css");
.h_1 {
  font-family: $font;
  font-weight: map-get($font-weight, "bb");
  font-size: 82px;
  line-height: 123px;
}

.h_2 {
  font-family: $font;
  font-weight: map-get($font-weight, "b");
  font-size: 68px;
  line-height: 102px;
}

.h_3 {
  font-family: $font;
  font-weight: map-get($font-weight, "b");
  font-size: 56px;
  line-height: 84px;
}

.h_4 {
  font-family: $font;
  font-weight: map-get($font-weight, "m");
  font-size: 46px;
  line-height: 69px;
}

.h_5 {
  font-family: $font;
  font-weight: map-get($font-weight, "m");
  font-size: 38px;
  line-height: 57px;
}

.h_6 {
  font-family: $font;
  font-weight: map-get($font-weight, "m");
  font-size: 32px;
  line-height: 48px;
}

.h_7 {
  font-family: $font;
  font-weight: map-get($font-weight, "m");
  font-size: 26px;
  line-height: 39px;
}

.h_8 {
  font-family: $font;
  font-weight: map-get($font-weight, "m");
  font-size: 22px;
  line-height: 33px;
}

.b_1 {
  font-family: $font;
  font-weight: map-get($font-weight, "r");
  font-size: 18px;
  line-height: 27px;
}

.b_2 {
  font-family: $font;
  font-weight: map-get($font-weight, "r");
  font-size: 16px;
  line-height: 24px;
}

.b_3 {
  font-family: $font;
  font-weight: map-get($font-weight, "r");
  font-size: 14px;
  line-height: 21px;
}

.b_4 {
  font-family: $font;
  font-weight: map-get($font-weight, "r");
  font-size: 12px;
  line-height: 18px;
}

.non_select {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &--img {
    @extend .non_select;
    pointer-events: none;
  }
}

@include mediaQuery(1920px) {
  // 1440
}

@include mediaQuery(1440px) {
  // 1280
}

@include mediaQuery(1280px) {
  // 1024
}

@include mediaQuery(1024px) {
  // 768
}

@include mediaQuery(768px) {
  // 480
}

@include mediaQuery(480px) {
  // 300
}
