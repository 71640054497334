html {
  scrollbar-color: map-get($color, "primary/300")
    map-get($color, "primary/alpha/10");
  scrollbar-width: thin;
  #root {
    #overflow-register {
      scrollbar-width: thin;
    }
    .hide-scroll {
      scrollbar-color: auto;
      scrollbar-width: auto;
      &::-webkit-scrollbar {
        width: 3px;
      }
      &::-webkit-scrollbar-track {
        background: map-get($color, "primary/alpha/10");
      }
      &::-webkit-scrollbar-thumb {
        background: map-get($color, "primary/500");
        border-radius: 2px;
      }
    }
  }
  &.firefox {
    scrollbar-color: map-get($color, "primary/300")
      map-get($color, "primary/alpha/10");
    scrollbar-width: auto;
    #root {
      #overflow-register {
        scrollbar-width: auto;
      }
      .hide-scroll {
        scrollbar-color: map-get($color, "primary/300")
          map-get($color, "primary/alpha/10");
        scrollbar-width: thin;
      }
    }
  }
}
