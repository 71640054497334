#root {
  .prediction_page {
    margin-top: 75px;
    min-height: 100vh;
    padding: 80px $main-gap 135px $main-gap;
    .hood.h_5 {
      text-align: center;
      // margin-bottom: 45px;
    }
    .hood.h_8 {
      margin-top: 25px;
      text-align: center;
    }
    .prediction_page_content {
      display: flex;
      flex-direction: column;
      gap: 60px;
      .right_to_and_points {
        display: flex;
        gap: 36px;
        justify-content: space-between;
        margin-top: 40px;
        width: 100%;
        .right_to_predictions,
        .total_points {
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: map-get($color, "neutral/100");
          @include box_shadow(map-get($color, "dark/alpha/25"));
          border-radius: 16px;
          padding: 12px;
          width: calc((100% / 2) - (36px / 2));
          div.hood {
            display: flex;
            align-items: center;
            gap: 10px;
            figure {
              // width: 60px;
              height: 35px;
              display: flex;
              img {
                width: 100%;
                height: 100%;
              }
            }
            h6.h_8 {
              display: flex;
              align-items: flex-start;
              gap: 5px;
              .info {
                position: relative;
                cursor: pointer;
                figure {
                  width: 16px;
                  height: 16px;
                  padding: 2px;
                  border-radius: 50%;
                  border: 1px solid map-get($color, "neutral/700");
                  svg {
                    width: 100%;
                    height: 100%;
                    fill: map-get($color, "neutral/700");
                  }
                }
                .modal {
                  position: absolute;
                  bottom: 16px;
                  left: 16px;
                  padding: 14px 8px;
                  border: 1px solid map-get($color, "dark");
                  background: map-get($color, "neutral/100");
                  border-radius: 4px 4px 4px 0;
                  width: max-content;
                  display: none;
                }
                &:hover {
                  .modal {
                    display: inline-block;
                  }
                }
              }
            }
          }
          .remaining_prediction {
          }
        }
      }
      .predictions_and_settings {
        display: flex;
        flex-direction: column;
        // justify-content: space-between;
        .predictions {
          display: flex;
          // flex-direction: column;
          flex-wrap: wrap;
          gap: 36px;
          width: 100%;
          margin-bottom: 60px;
          // .prediction {
          //   // flex: 1 1 calc((100% / 2) - (36px));
          //   @include box_shadow(map-get($color, "dark/alpha/25"));
          //   border-radius: 16px;
          //   background-color: map-get($color, "neutral/100");
          //   display: flex;
          //   flex-direction: column;
          //   align-items: center;
          //   justify-content: space-between;
          //   padding: 12px;
          //   gap: 12px;
          //   width: calc((100% / 2) - (36px / 2));
          //   overflow: hidden;
          //   .prediction_content {
          //     width: 100%;
          //     // flex: 1 1 50%;
          //     h5.h_8 {
          //       text-align: center;
          //       color: map-get($color, "dark/alpha/50");
          //     }
          //     .teams {
          //       .team_names {
          //         display: flex;
          //         align-items: center;
          //         justify-content: space-between;
          //         .team {
          //           flex: 1 1;
          //           color: map-get($color, "dark/alpha/50");
          //           &.left {
          //             text-align: left;
          //           }
          //           &.right {
          //             text-align: right;
          //           }
          //         }
          //         span.vs {
          //           flex: 1 1;
          //           display: flex;
          //           align-items: center;
          //           justify-content: center;
          //           color: map-get($color, "dark/alpha/50");
          //         }
          //       }
          //       .teams_last_matches {
          //         display: flex;
          //         align-items: center;
          //         justify-content: space-between;
          //         .team_last_matches {
          //           display: flex;
          //           flex-direction: column;
          //           gap: 5px;
          //           .b_4 {
          //             color: map-get($color, "dark/alpha/50");
          //           }
          //           .data {
          //             display: flex;
          //             align-items: center;
          //             gap: 8px;
          //             .match_info {
          //               display: inline-block;
          //               width: 10px;
          //               height: 10px;
          //               border-radius: 50%;
          //               border: 1px solid map-get($color, "dark/alpha/50");
          //               position: relative;
          //               .modal {
          //                 display: none;
          //                 position: absolute;
          //                 top: 12px;
          //                 z-index: 1;
          //                 padding: 8px;
          //                 color: map-get($color, "dark");
          //                 border: 1px solid map-get($color, "dark");
          //                 background: map-get($color, "neutral/100");
          //                 // border-radius: 4px 4px 4px 0;
          //                 width: max-content;
          //               }
          //               &.W {
          //                 background-color: map-get($color, "green/200");
          //                 border: 1px solid map-get($color, "green/200");
          //               }
          //               &.L {
          //                 background-color: map-get($color, "red/200");
          //                 border: 1px solid map-get($color, "red/200");
          //               }
          //               &.D {
          //                 background-color: map-get($color, "yellow/300");
          //                 border: 1px solid map-get($color, "yellow/300");
          //               }
          //               &:hover {
          //                 .modal {
          //                   display: inline-block;
          //                 }
          //               }
          //             }
          //           }
          //           &.left {
          //             text-align: left;
          //             align-items: flex-start;
          //             .modal {
          //               border-radius: 0px 4px 4px 4px;
          //               left: 3px;
          //               text-align: left;
          //             }
          //           }
          //           &.right {
          //             text-align: right;
          //             align-items: flex-end;
          //             .modal {
          //               right: 3px;
          //               border-radius: 4px 0px 4px 4px;
          //               text-align: right;
          //             }
          //             .data {
          //               flex-direction: row-reverse;
          //             }
          //           }
          //         }
          //       }
          //     }
          //   }
          //   .selected_and_delete {
          //     display: flex;
          //     flex-direction: column;
          //     align-items: center;
          //     gap: 10px;
          //     width: 100%;
          //     .selected_and_point {
          //       // display: flex;
          //       // justify-content: center;
          //       // flex: 1 1 40%;
          //       display: flex;
          //       align-items: center;
          //       justify-content: space-between;
          //       width: 100%;
          //       .prediction_selected {
          //         flex: 1 1;
          //         .h_8 {
          //           display: flex;
          //           align-items: center;
          //           flex-direction: column;
          //           // gap: 10px;
          //           span {
          //             color: map-get($color, "primary/300");
          //           }
          //         }
          //       }
          //       .prediction_point {
          //         flex: 1 1;
          //         .h_8 {
          //           display: flex;
          //           align-items: center;
          //           flex-direction: column;
          //           // gap: 10px;
          //           span {
          //             color: map-get($color, "primary/300");
          //           }
          //         }
          //       }
          //     }
          //     .delete {
          //       // flex: 1 1 10%;
          //       .background_btn {
          //         padding: 6px 36px;
          //         .b_4 {
          //         }
          //       }
          //     }
          //   }
          // }
          .no_prediction {
            display: flex;
            flex-direction: column;
            gap: 60px;
            h6.h_7 {
            }
            .background_btn.b_3 {
              font-weight: map-get($font-weight, "b");
              padding: 18px;
              border-radius: 1500px;
            }
          }
        }
        .background_btn {
          // margin-top: 60px;
          border-radius: 1500px;
        }
      }
    }
  }
}
