#root {
  .verify-code-inputs {
    .vi__container {
      width: 100%;
      gap: 24px;
      height: fit-content;
      .vi__character {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1 1;
        aspect-ratio: 1 / 1;
        height: auto;
        // min-height: 50px;
        padding: 8px;
        // border: 5px solid map-get($color, "primary/300");
        border-radius: 12px;
        font-size: 56px;
        font-weight: map-get($font-weight, "b");
        line-height: 84px;
        border: none;
        @include box_shadow(map-get($color, "dark/alpha/25"));
        transition: $transition;
        &.vi__character--inactive {
          background-color: map-get($color, "neutral/100");
        }
        &:where(.vi__character--selected) {
          // outline: 2px solid map-get($color, "primary/200");
          outline: none;
          @include box_shadow(map-get($color, "primary/alpha/50"));
          color: map-get($color, "primary/300");
        }
      }
    }
  }
}
