.alertify {
  .ajs-dialog {
    overflow: hidden;
    border-radius: 16px;
    .ajs-header {
      @extend .h_8;
    }
    .ajs-body {
      .ajs-content {
        @extend .b_1;
      }
    }
    .ajs-footer {
      .ajs-ok {
        @extend .b_4;
        @extend .outline_btn;
        display: inline-block !important;
        padding: 10px !important;
        font-weight: map-get($font-weight, "bb");
      }
    }
  }
}
