#root {
  .phone-input {
    display: flex;
    border-radius: 8px;
    @include box_shadow(map-get($color, "dark/alpha/25"));
    input {
      border: none;
      border-radius: 0 8px 8px 0;
      padding: 15px 10px;
    }
    &:has(.select-trigger.open) {
      border-radius: 8px 8px 8px 0;
    }
    &.correct {
      @include box_shadow(map-get($color, "green/alpha/50"));
      .phone-dropdown {
        .options {
          @include box_shadow(map-get($color, "green/alpha/50"));
        }
      }
    }
    &.wrong {
      @include box_shadow(map-get($color, "red/alpha/50"));
      .phone-dropdown {
        .options {
          @include box_shadow(map-get($color, "red/alpha/50"));
        }
      }
    }
  }
  .input {
    min-width: 250px;
    border: none;
    border-radius: 8px;
    padding: 15px 10px;
    @include box_shadow(map-get($color, "dark/alpha/25"));
    // @include box_shadow(map-get($color, "footer/alpha/25"));
    &.correct {
      @include box_shadow(map-get($color, "green/alpha/50"));
    }
    &.wrong {
      @include box_shadow(map-get($color, "red/alpha/50"));
    }
  }
}
