#root {
  .custom-checkbox {
    display: block;
    position: relative;
    padding-left: 20px;
    cursor: pointer;
    input[type="checkbox"] {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
      &:checked {
        ~ .checkmark {
          background-color: map-get($color, "neutral/200");
          svg {
            display: block;
          }
        }
      }
    }
    .checkmark {
      position: absolute;
      top: calc(50% - (16px / 2));
      left: 0;
      height: 16px;
      width: 16px;
      border-radius: 4px;
      background-color: map-get($color, "neutral/300");
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        position: absolute;
        display: none;
        width: 10px;
      }
    }
    &:hover {
      .checkmark {
        background-color: map-get($color, "neutral/400");
      }
    }
  }
}
